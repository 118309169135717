<script lang="ts">
    import TextBox from 'components/form/text-box.svelte'
    import CheckBox from 'components/form/check-box.svelte'
    import { auctionAccount } from '_config/constants/signup'

    export let herdCode = ''
    export let herdCodeRequired = false
    export let noCodeInformation = false
    export let participantCode = ''
    export let participantCodeRequired = false

    let herdClicked = false
    let herdOriginalCode = ''
    let participantClicked = false
    let participantOriginalCode = ''

    $: {
        if (!noCodeInformation && herdClicked) {
            herdCode = herdOriginalCode
            herdClicked = false
        }

        if (noCodeInformation) {
            herdOriginalCode = herdCode
            herdCode = ''
            herdClicked = true
        }
    }

    $: {
        if (!noCodeInformation && participantClicked) {
            participantCode = participantOriginalCode
            participantClicked = false
        }

        if (noCodeInformation) {
            participantOriginalCode = participantCode
            participantCode = ''
            participantClicked = true
        }
    }
</script>

<div class="register-code-information-container">
    <div class="header">Please Enter your Dairy Information as part of your auction registration</div>

    {#if herdCodeRequired}
        <div class="herd-code">
            <div class="code-label">Herd Code</div>
            <div>
                <TextBox required={true} bind:value={herdCode} disabled={noCodeInformation} showErrorMessage={false} maxlength={auctionAccount.herdCode} id="herd-code-input" />
            </div>
        </div>
    {/if}

    {#if participantCodeRequired}
        <div class="participant-code">
            <div class="code-label">Participant Code</div>
            <div>
                <TextBox
                    required={true}
                    bind:value={participantCode}
                    disabled={noCodeInformation}
                    showErrorMessage={false}
                    maxlength={auctionAccount.participantCode}
                    id="participant-code-input"
                />
            </div>
        </div>
    {/if}

    <div class="no-code-information">
        <div>I don't know this information. Please contact me after the sale to confirm my details</div>
        <div>
            <CheckBox bind:value={noCodeInformation} id="dairy-info-checkbox" />
        </div>
        {#if ((herdCodeRequired && herdCode === '') || (participantCodeRequired && participantCode == '')) && !noCodeInformation}
            <div class="error-message"><small>Please enter Dairy Information or select the checkbox</small></div>
        {/if}
    </div>
</div>

<style lang="scss">
    .register-code-information-container {
        display: grid;
        grid-template-rows: repeat(4, max-content);
        margin-top: 2rem;

        div {
            color: var(--color-secondary);
            font-size: var(--font-size);

            &.header {
                background-color: rgba(60, 76, 84, 0.2);
                font-weight: 700;
                padding: 1rem;
            }

            &.herd-code,
            &.participant-code {
                display: grid;
                grid-template-columns: max-content max-content;
                align-items: center;
                padding: 1rem;
                border-top: 1px solid var(--color-grey);

                @media (max-width: 768px) {
                    grid-template-columns: unset;
                }

                input:hover {
                    background: #fff !important;
                }

                div {
                    padding-right: 1rem;
                    @media (max-width: 768px) {
                        :global(input) {
                            font-size: 10px;
                        }
                    }
                }

                .code-label {
                    width: 150px;
                }

                .error-message {
                    grid-column: 1/3;
                }
            }

            &.participant-code {
                padding-bottom: 20px;
            }

            &.no-code-information {
                display: grid;
                grid-template-columns: 3fr 1fr;
                align-items: center;
                padding: 0 1rem 1rem 1rem;

                div {
                    display: grid;
                    justify-content: start;
                    padding-right: 1rem;
                }
                .error-message {
                    padding: 0.5rem 0;
                    grid-column: 1/3;
                }
            }

            .error-message {
                color: var(--color-error);
            }

            .error-message-hidden {
                height: 34px;
                visibility: hidden;
            }

            &:not(.header) {
                background-color: #f5f5f5;
            }
        }
    }
</style>
