<script lang="ts">
    import ConnectModalTemplate from 'components/modal/modal-template.svelte'
    import Button from '@cucumber/ui/components/buttons/button.svelte'
    import { auctionUrl } from 'stores/url.store'
    import { goto } from '$app/navigation'
    import { activeAuction } from 'stores/auction.store'
    import { displayModal, redirectFromConnect } from '@cucumber/ui/stores/ui.store'
    import { getCookieData } from '_auth/helpers/auth.helper'

    let isCloseModal: boolean

    function watch() {
        const cookie = getCookieData()
        document.cookie = `fromApp=yes;path=/;domain=${cookie.domain};`
        return goto(`${$auctionUrl}/auctions/${$activeAuction?.auctionId}/`)
    }

    function bid() {
        if ($activeAuction?.isRegistered) {
            const cookie = getCookieData()
            document.cookie = `fromApp=yes;path=/;domain=${cookie.domain};`
            goto(`${$auctionUrl}/auctions/${$activeAuction?.auctionId}/`)
        } else {
            $redirectFromConnect = true
            $displayModal = 'register-modal'
        }
    }

    function closeModal() {
        $displayModal = null
    }

    $: if (isCloseModal) closeModal()
</script>

<ConnectModalTemplate modalTitle="Connect to Auction" bind:isCloseModal>
    <div slot="body">
        <span>
            <p>You are not registered to bid in this Auction: do you want to bid or just watch?</p>
        </span>
    </div>

    <div slot="action">
        <div class="action">
            <div>
                <Button on:click={bid} label="I want to bid" />
            </div>
            <div>
                <Button on:click={watch} label="I want to watch" />
            </div>
        </div>
    </div>
</ConnectModalTemplate>

<style lang="scss">
    .action {
        width: fit-content;

        display: grid;
        grid-auto-flow: column;

        @media (max-width: 370px) {
            grid-template-rows: max-content max-content;
        }

        div {
            padding: 1rem;

            @media (max-width: 370px) {
                padding: 0.5rem 1rem;

                &:last-child {
                    padding-bottom: 2rem;
                }
            }
        }
    }
    p {
        text-align: center;
    }
</style>
