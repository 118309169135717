<script lang="ts">
    export let isImageModal: boolean

    import { createEventDispatcher } from 'svelte'

    const dispatch = createEventDispatcher()
    const close = () => dispatch('close')

    const handle_keydown = (e: any) => {
        if (e.key === 'Escape') {
            close()
            return
        }
    }
</script>

<svelte:window on:keydown={handle_keydown} />

<div class="modal-background" on:click={close} />

<div class="modal" role="dialog" aria-modal="true" class:image={isImageModal}>
    <slot />
</div>

<style lang="scss">
    .modal-background {
        position: fixed;
        top: 0;
        left: 0;
        max-width: var(--bp-2200);
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.3);
        z-index: 9999;
        max-width: 100vw;
    }

    .modal {
        position: fixed;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -30%);

        width: 50vw;
        max-height: calc(100vh - 4em);
        overflow: auto;
        overflow-x: hidden;
        border-radius: 0.2em;
        background: white;

        margin: 0 auto;
        z-index: 10000;

        display: grid;
        grid-auto-rows: min-content;

        @media (max-width: 1024px) {
            width: calc(100vw - 2rem);
        }

        &.image {
            width: 75vw;
            overflow-y: auto;
        }
    }
</style>
