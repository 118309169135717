<script lang="ts">
    import RegisterModalTemplate from 'components/modal/modal-template.svelte'
    import Button from '@cucumber/ui/components/buttons/button.svelte'
    import { displayModal, redirectFromConnect } from '@cucumber/ui/stores/ui.store'
    import { activeAuction } from 'stores/auction.store'
    import { auctionUrl } from 'stores/url.store'
    import { goto } from '$app/navigation'
    import { getCookieData } from '_auth/helpers/auth.helper'

    let modalTitle = $redirectFromConnect ? 'Connect to Auction' : 'Register for Auction'
    let isCloseModal: boolean

    function handleClick() {
        closeModal()
        goto(`user/profile`)
    }

    function watch() {
        const cookie = getCookieData()
        document.cookie = `fromApp=yes;path=/;domain=${cookie.domain};`
        return goto(`${$auctionUrl}/auctions/${$activeAuction?.auctionId}/`)
    }

    function closeModal() {
        $displayModal = null
    }

    $: if (isCloseModal) closeModal()
</script>

<RegisterModalTemplate {modalTitle} bind:isCloseModal>
    <div slot="body">
        <span>
            <p>In order to bid you need an approved trading account linked to your bidr profile.</p>
            {#if !$redirectFromConnect}
                <p>
                    Use the link below to add a trading account to your profile. For further assistance please call 0800 TO BIDR (0800 86 2437) with your trading account number
                    ready.
                </p>
            {:else}
                <p>If you wish to bid at this sale, please contact 0800 TO BIDR (0800 86 2437) with your trading account number ready for assistance</p>
            {/if}
            <p class="additional">Please note: Your trading account number can be found on your latest invoice, or by getting in contact with your respective agency.</p>
        </span>
    </div>

    <div slot="action">
        <div class="action">
            <div>
                <Button on:click={closeModal} style="outlined" label="Cancel" />
            </div>
            {#if !$redirectFromConnect}
                <div>
                    <Button on:click={handleClick} label="Add Account" />
                </div>
            {:else}
                <div>
                    <Button on:click={watch} label="I want to watch" />
                </div>
            {/if}
        </div>
    </div>
</RegisterModalTemplate>

<style lang="scss">
    p:not(.additional) {
        color: var(--color-accent);
    }

    .action {
        width: fit-content;

        display: grid;
        grid-auto-flow: column;

        div {
            padding: 1rem;
        }
    }
</style>
