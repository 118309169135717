<script lang="ts">
    export let text: string

    import Loading from '@cucumber/ui/components/loading.svelte'
</script>

<div class="loadingContainer">
    <Loading {text} />
</div>

<style lang="scss">
    .loadingContainer {
        opacity: 0.8;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: grid;

        align-items: center;
        justify-items: center;

        background: var(--color-background);

        z-index: 9999998;
        max-width: unset;
    }
</style>
