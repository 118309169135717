<script context="module" lang="ts">
    import { setClient, operationStore } from '@urql/svelte'

    import { get } from 'svelte/store'

    import { createGraphqlClient } from '_config/graphql/client'
    import { AppContentDocument, CatchAllPagesDocument } from '_config/graphql/tags-generated'

    import { defineAuthStoresFromLoad } from '_auth/auth.svelte'

    import { gtmCode } from 'stores/google-tag-manager.store'
    import { auctionUrl, managementUrl } from 'stores/url.store'
    import { maxAuctionNumber, minAuctionNumber, maxUpcomingAuctionNumber } from 'stores/auction.store'

    import { metaTitle, metaDescription, ogTitle, ogDescription, ogImage } from '@cucumber/ui/stores/ui.store'
    import { navigating } from '$app/stores'

    export async function load({ url, session }: LoadEvent) {
        let pageName = url.pathname.substring(url.pathname.lastIndexOf('/') + 1) == '' ? 'home' : url.pathname.substring(url.pathname.lastIndexOf('/') + 1)

        if (typeof Number(pageName) == 'number' && url.pathname.includes('/auction/')) {
            pageName = 'auction'
        }

        const _client = createGraphqlClient(session.graphqlUrl)
        defineAuthStoresFromLoad(session)

        cdnUrl.set(`${session.directusUrl}/assets`)
        gtmCode.set(session.graphqlUrl)
        auctionUrl.set(session.auctionUrl)
        managementUrl.set(session.managementUrl)

        if (!isNaN(Number(session.maxAuctionNumber))) maxAuctionNumber.set(Number(session.maxAuctionNumber))
        if (!isNaN(Number(session.minAuctionNumber))) minAuctionNumber.set(Number(session.minAuctionNumber))
        if (!isNaN(Number(session.maxUpcomingAuctionNumber))) maxUpcomingAuctionNumber.set(Number(session.maxUpcomingAuctionNumber))

        const ssrRunQuery = async (queryDocument: DocumentNode, variables?: Object): Promise<OperationStore> => {
            const store = operationStore(queryDocument, variables)
            const result = await _client.query(queryDocument, variables).toPromise()
            Object.assign(get(store), result)
            return store
        }

        const query = await ssrRunQuery(CatchAllPagesDocument, { name: pageName })
        const page = query.data?.directus?.page![0]! as Page

        metaTitle.set('')
        metaDescription.set('')
        ogTitle.set('')
        ogDescription.set('')
        ogImage.set('')

        if (page) {
            const title = getMetaFromPage(page)
            metaTitle.set(title?.toString())

            const description = getMetaDescription(page)
            metaDescription.set(description?.toString())

            const og_title = getMetaOGTitle(page)
            ogTitle.set(og_title?.toString())

            const og_description = getOGDescription(page)
            ogDescription.set(og_description?.toString())

            const og_image = getOGImage(page)
            ogImage.set(og_image)
        }

        return {
            stuff: { ssrRunQuery, page },
            props: {
                _client,
                wsGraphqlUrl: session.wsGraphqlUrl,
                appContentQueryStore: await ssrRunQuery(AppContentDocument),
                pageName: pageName,
                pageData: page,
                session: session,
                url: url.pathname,
            },
        }
    }
</script>

<script lang="ts">
    export let _client: GraphqlClient
    export let wsGraphqlUrl: string
    export let appContentQueryStore: AppContentQueryStore
    export let pageName: string
    export let session: App.Session
    export let pageData: Page
    export let url: string

    setClient(_client)

    import 'styles/global.scss'

    import { onMount } from 'svelte'

    import { cdnUrl, isLoading } from '@cucumber/ui/stores/ui.store'

    import Auth from '_auth/auth.svelte'
    import Header from 'components/page/header.svelte'
    import Footer from 'components/page/footer.svelte'

    import LoadingUiBlocker from '@cucumber/ui/components/ui-blocker.svelte'
    import { isMobileMenuOpened } from '@cucumber/ui/stores/ui.store'
    import { isAuthMenuOpened } from '_auth/stores/auth.store'

    import * as dateFns from 'date-fns'
    import { createGraphqlWSClient } from 'graphqlClient/client'
    import { browser } from '$app/env'
    import { navMain, navFooter as navSecond } from '_config/constants/menu'
    import { isAuthenticated } from '_auth/stores/auth.store'

    import Auction from '_data/_api/auctions/auction.svelte'
    import AuctionRegister from '_data/_api/auctions/register.svelte'
    import Signup from '_data/_api/signup.svelte'
    import Profile from '_data/_api/profile.svelte'
    import ModalsRegistry from 'components/modal/modal-registry.svelte'

    import { getMetaDescription, getMetaFromPage, getMetaOGTitle, getOGDescription, getOGImage } from 'helpers/meta-helpers'

    let afterActionReturnPath: string | null

    if (browser) {
        afterActionReturnPath = localStorage.getItem('after_action_return_path')
        createGraphqlWSClient(wsGraphqlUrl)
    }

    onMount(() => {
        window.dateFns = dateFns

        if (!afterActionReturnPath) return defaultReturn()
        if (!!afterActionReturnPath && !$isAuthMenuOpened) return defaultReturn()
    })

    function defaultReturn() {
        setTimeout(() => {
            $isLoading = false
        })
    }

    $: contactUs = $appContentQueryStore.data?.directus?.contact_us[0] as Maybe<Plain_Text>
    $: navPrimary = $appContentQueryStore.data?.directus?.nav_primary[0].children?.flatMap((i: any) => i.related_menus_id ?? []) ?? navMain
    $: navFooter = $appContentQueryStore.data?.directus?.nav_footer[0] ?? navSecond

    $: if (browser && $isMobileMenuOpened) document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    $: if (browser && !$isMobileMenuOpened) document.getElementsByTagName('body')[0].style.overflow = 'unset'

    $: if (browser && $isAuthMenuOpened) document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    $: if (browser && !$isAuthMenuOpened) document.getElementsByTagName('body')[0].style.overflow = 'unset'

    $: if (browser && $isAuthenticated && afterActionReturnPath) {
        localStorage.removeItem('after_action_return_path')

        switch (true) {
            case ![location.pathname, '/signup'].includes(afterActionReturnPath) && !afterActionReturnPath.includes('confirm-account'):
                location.pathname = afterActionReturnPath

                break

            case location.pathname == '/signup':
            case afterActionReturnPath.includes('confirm-account'):
                location.pathname = '/'

                break

            default:
                defaultReturn()
        }
    }
</script>

<svelte:head>
    <title>{$metaTitle ?? 'Bidr'}</title>
    <meta name="description" content={$metaDescription} />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={$ogTitle} />
    <meta property="og:description" content={$ogDescription} />
    <meta property="og:image" content={$ogImage} />
    <meta property="og:url" content={`${session.redirectUrl}${pageName == 'home' ? '/' : url}`} />
    <meta property="og:site_name" content="bidr" />

    {#if pageData?.meta_robots_no_index}
        <meta name="robots" content="noindex, nofollow" />
    {/if}
</svelte:head>

<Profile />
<Signup />
<AuctionRegister />
<Auction />
<Auth />
<ModalsRegistry />

{#if $isLoading}
    <LoadingUiBlocker text="loading" />
{:else}
    <Header menu={navPrimary} />

    {#if $navigating}
        <div class="progress-bar" />
        <div class="progress-bar-animation" />
        <div class="progress-bar-overlay" />
    {/if}

    <content>
        <main>
            <slot />
        </main>
    </content>

    <Footer menu={navFooter} {contactUs} />
{/if}

<style lang="scss">
    content {
        margin: 0 auto;
        width: 100%;

        main {
            display: grid;

            grid-auto-rows: min-content;
        }
    }

    .progress-bar,
    .progress-bar-animation {
        background-color: #ffd6c8;
        position: fixed;
        top: var(--header-height);
        width: 100%;
        height: 0.4rem;
        background-color: var(--color-accent);
        opacity: 1;
        z-index: 1000;
        max-width: none;
    }

    .progress-bar-animation {
        background-color: #fff;
        animation: customIndeterminateAnimation 2s infinite;
        width: 100%;
        opacity: 0.4;
    }

    .progress-bar-overlay {
        background: #fff;
        opacity: 0.15 !important;
        height: 100vh;
        width: 100vw;
        max-width: none;
        position: fixed;
        z-index: 999;
    }

    /* Define a custom animation for the indeterminate bar */
    @keyframes customIndeterminateAnimation {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(100%);
        }
    }
</style>
