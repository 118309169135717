<script lang="ts">
    import { createEventDispatcher } from 'svelte'
    import ImageModalControls from 'components/modal/image-modal-controls.svelte'
    import { displayModal } from '@cucumber/ui/stores/ui.store'

    const dispatch = createEventDispatcher()
    const close = () => dispatch('close')

    const handle_keydown = (e: any) => {
        if (e.key === 'Escape') {
            close()
            return
        }
    }
</script>

<svelte:window on:keydown={handle_keydown} />

<div class="modal-background" on:click={close}>
    <ImageModalControls />
</div>

<div class="modal" class:image-preview={$displayModal == 'image-preview-modal'} role="dialog" aria-modal="true">
    <slot />
</div>

<style lang="scss">
    .modal-background {
        position: fixed;
        top: 0;
        left: 0;
        max-width: var(--bp-2200);
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.9);
        z-index: 9999;
        max-width: 100vw;
    }

    .modal {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        width: 50vw;
        min-width: fit-content;
        min-height: fit-content;
        max-height: calc(100vh - 4em);
        overflow: auto;
        border-radius: 0.2em;
        background: white;

        margin: 0 auto;
        z-index: 10000;

        display: grid;
        grid-auto-rows: min-content;

        &.image-preview {
            background: transparent;
            overflow: hidden;
        }

        @media (max-width: 1024px) {
            width: calc(100vw - 4em);
        }
    }
</style>
