<script lang="ts">
    import { displayModal } from '@cucumber/ui/stores/ui.store'
    import ConnectNotRegistered from 'modules/auctions/connect/connect-not-registered.svelte'
    import { activeAuction, auctionByIdResponse } from 'stores/auction.store'
    import { auctionUrl } from 'stores/url.store'
    import { goto } from '$app/navigation'
    import { browser } from '$app/env'
    import { isLoading } from '@cucumber/ui/stores/ui.store'
    import { getAuctionById } from '_data/_api/auctions/auction.svelte'
    import { getClient } from '@urql/svelte'

    let isRegistered = false
    const client = getClient()

    if (browser) {
        if ($activeAuction && $activeAuction.isRegistered) redirectToAuctionSite()
        else apiGetAuctionDetail(checkIfRegistered)
    }

    function checkIfRegistered() {
        isRegistered = $auctionByIdResponse?.bidAccounts && $auctionByIdResponse?.bidAccounts.length > 0 ? true : false
        $isLoading = false

        if (isRegistered) {
            redirectToAuctionSite()
        }
    }

    function redirectToAuctionSite() {
        $displayModal = null
        goto(`${$auctionUrl}/auctions/${$activeAuction?.auctionId}/`)
    }

    //api functions
    async function apiGetAuctionDetail(callback: Function) {
        $auctionByIdResponse = await getAuctionById($activeAuction?.auctionId as number, client)

        localStorage.removeItem('after_logged_in')

        callback()
    }
</script>

{#if $auctionByIdResponse}
    {#if !isRegistered}
        <ConnectNotRegistered />
    {/if}
{/if}
