<script lang="ts">
    export let id: string
    export let name: string
    export let value: string
    export let label: string
    export let selected: any
</script>

<label>
    <input {id} type="radio" bind:group={selected} {name} {value} />
    {label}
</label>

<style lang="scss">
    label {
        margin: 0;

        input[type='radio'] {
            width: 1.2rem;
            margin: 0;
            height: 4rem;
            box-sizing: border-box;
            display: inline-block;
            opacity: 1;

            box-shadow: unset;
            cursor: pointer;
            -moz-appearance: radio;
        }
    }
</style>
