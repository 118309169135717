<script lang="ts">
    export let noNait = false
    export let naitNumber = ''
    export let naitOriginalNumber = ''
    export let isNaitNumberValid = false
    export let checkClicked = false

    import TextBox from 'components/form/text-box.svelte'
    import CheckBox from 'components/form/check-box.svelte'

    function naitNumberValidation() {
        isNaitNumberValid = naitNumber.match(/^[0-9]{2,6}$|^[0-9]{8}$/) != null
    }
    $: {
        if (!noNait && checkClicked) {
            naitNumber = naitOriginalNumber
            checkClicked = false
        }

        if (noNait) {
            naitOriginalNumber = naitNumber
            naitNumber = ''
            checkClicked = true
        }
    }
</script>

<div class="register-nait-container">
    <div class="header">Please enter your NAIT number as part of your auction registration</div>

    <div class="nait-number">
        <div>NAIT Number</div>
        <div>
            <TextBox
                isError={!isNaitNumberValid}
                required={true}
                bind:value={naitNumber}
                disabled={noNait}
                on:input={naitNumberValidation}
                showErrorMessage={false}
                id="nait-number"
            />
        </div>
        {#if naitNumber != '' && !isNaitNumberValid}
            <div class="error-message"><small>Incorrect NAIT number format.</small></div>
        {/if}
    </div>

    <div class="no-nait">
        <div>I don't know my NAIT number. Please contact me after the sale to confirm my details</div>
        <div>
            <CheckBox bind:value={noNait} id="nait-checkbox" />
        </div>
        {#if naitNumber == '' && !noNait}
            <div class="error-message"><small>Please enter a NAIT Number or select the checkbox above.</small></div>
        {/if}
    </div>
</div>

<style lang="scss">
    .register-nait-container {
        display: grid;
        grid-template-rows: repeat(4, max-content);
        margin-top: 2rem;

        div {
            color: var(--color-secondary);
            font-size: var(--font-size);

            &.header {
                background-color: rgba(60, 76, 84, 0.2);
                font-weight: 700;
                padding: 1rem;
            }

            &.nait-number {
                display: grid;
                grid-template-columns: max-content max-content;
                align-items: center;
                padding: 1rem;
                border-top: 1px solid var(--color-grey);

                @media (max-width: 400px) {
                    grid-template-columns: unset;
                }

                div {
                    padding-right: 1rem;
                    @media (max-width: 400px) {
                        :global(input) {
                            font-size: 10px;
                        }
                    }
                }

                .error-message {
                    grid-column: 1/3;
                }
            }

            &.no-nait {
                display: grid;
                grid-template-columns: 3fr 1fr;
                align-items: center;
                padding: 0 1rem 1rem 1rem;

                div {
                    display: grid;
                    justify-content: start;
                    padding-right: 1rem;
                }
                .error-message {
                    padding: 0.5rem 0;
                    grid-column: 1/3;
                }
            }
            .error-message {
                color: var(--color-error);
            }
            &:not(.header) {
                background-color: #f5f5f5;
            }
        }
    }
</style>
