<script lang="ts">
    import { onMount } from 'svelte'

    import { displayModal } from '@cucumber/ui/stores/ui.store'
    import { imagePreviewInfo } from 'stores/auction.store'

    let minZoomLevel = -2
    let maxZoomLevel = 3

    onMount(() => {
        if ($imagePreviewInfo) $imagePreviewInfo.imageZoomLevel = 0
    })

    function handleMinusClick() {
        if ($imagePreviewInfo && $imagePreviewInfo.imageZoomLevel > minZoomLevel) $imagePreviewInfo.imageZoomLevel -= 1
    }

    function handlePlusClick() {
        if ($imagePreviewInfo && $imagePreviewInfo.imageZoomLevel < maxZoomLevel) $imagePreviewInfo.imageZoomLevel += 1
    }

    function handleCloseClick() {
        $displayModal = null
    }
</script>

{#if $displayModal == 'image-preview-modal'}
    <i class="fas search-minus" class:disabled={$imagePreviewInfo?.imageZoomLevel == minZoomLevel} on:click={handleMinusClick} />
    <i class="fas search-plus" class:disabled={$imagePreviewInfo?.imageZoomLevel == maxZoomLevel} on:click={handlePlusClick} />
    <i class="fas close" on:click={handleCloseClick} />

    {#if $imagePreviewInfo?.activeIndex != null}
        <span>
            {$imagePreviewInfo?.activeIndex + 1} / {$imagePreviewInfo?.imagesUrl?.length}
        </span>
    {/if}
{/if}

<style lang="scss">
    i {
        position: absolute;

        cursor: pointer;

        border: 0;
        color: var(--color-white);
        padding: 0;

        z-index: 1;
        &.search-minus {
            right: 11rem;
            top: 2rem;

            &::before {
                content: '\f010';
                font-size: 2.5rem;
            }
        }
        &.search-plus {
            right: 7rem;
            top: 2rem;

            &::before {
                content: '\f00e';
                font-size: 2.5rem;
            }
        }
        &.close {
            right: 3rem;
            top: 2rem;

            &::before {
                content: '\f057';
                font-size: 2.5rem;
            }
        }

        &.disabled {
            opacity: 0.6;
        }
    }

    span {
        z-index: 100;
        color: var(--color-white);
        font-size: 2rem;
        position: absolute;
        bottom: 1rem;
        left: 50%;
        transform: translateX(-50%);
    }
</style>
