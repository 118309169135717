<script lang="ts">
    import { BuyerStatus } from '_config/enums/buyer-status.enum'

    export let selectedAccountId: Maybe<number>
    export let selectedAccountStatus: Maybe<number>

    export let data: Maybe<AuctionAccountsResponse>

    $: accounts = data?.accounts as BuyerAccount[]

    $: if (data?.accounts?.length == 1) setDefaultAccountSelected()

    function setDefaultAccountSelected() {
        if (data?.accounts?.length) {
            selectedAccountId = data?.accounts[0]?.id as number
            selectedAccountStatus = data?.accounts[0]?.buyerStatus as number
        }
    }

    function selectAccount(id: number, status: number) {
        selectedAccountId = id
        selectedAccountStatus = status
    }
</script>

<div class="account-list-container">
    <span> Please select the account that you intend to register under for the {data?.auctionTitle} </span>
    <div>
        <div class="header">
            <div>Account Name</div>
            <div>Account No.</div>
            <div>Account Status</div>
            <div>&nbsp;</div>
        </div>

        {#each accounts as account}
            <div class:selected={selectedAccountId == account.id} on:click={() => selectAccount(account.id, account.buyerStatus)}>
                <div>{account.accountName}</div>
                <div>{account.accountNumber}</div>
                <div class:red={account.buyerStatus != BuyerStatus.Approved}>{account.buyerStatusDisplayText}</div>

                <div class:selected={selectedAccountId == account.id} on:click={() => selectAccount(account.id, account.buyerStatus)}>
                    {#if selectedAccountId == account.id}
                        <img src="/assets/images/green-tick.png" alt="account selected" />
                    {:else}
                        {''}
                    {/if}
                </div>
            </div>
        {/each}
    </div>
</div>

<style lang="scss">
    .account-list-container {
        display: grid;

        span {
            font-size: var(--font-size);
        }

        & > div {
            max-height: 230px;
            color: var(--color-secondary);
            margin-top: 2rem;
            overflow-y: auto;

            & > div {
                font-size: var(--font-size);

                &.header {
                    grid-column: 1/5;
                    padding: 0 1rem;
                    background-color: rgba(60, 76, 84, 0.2);
                    font-weight: 700;

                    display: grid;
                    grid-template-columns: repeat(3, 0.5fr) 6rem;

                    @media (max-width: 1024px) {
                        grid-template-columns: repeat(3, 0.5fr) max-content;
                    }

                    div {
                        padding: 1rem 0;
                    }
                }

                &:not(.header) {
                    background-color: #f5f5f5;
                    padding: 0 1rem;
                    border-top: 1px solid var(--color-grey);
                    word-break: break-word;

                    cursor: pointer;
                    grid-column: 1/5;

                    display: grid;
                    grid-template-columns: repeat(3, 0.5fr) 6rem;
                    grid-gap: 1rem;
                    align-items: center;

                    @media (max-width: 1024px) {
                        grid-template-columns: repeat(3, 0.5fr) max-content;
                    }

                    @media (max-width: 550px) {
                        grid-gap: 0.5rem;
                        padding: 0 0.5rem;
                    }

                    div {
                        padding: 1rem 0;

                        @media (max-width: 550px) {
                            font-size: 1.3rem;
                        }

                        &.red {
                            color: var(--color-error);
                        }
                    }
                }

                &.selected {
                    background-color: #e0f0d7;
                    border-top: 1px solid #379530;
                    border-bottom: 1.9px solid #379530;

                    img {
                        transform: translateY(3px);
                    }
                }
            }
        }
    }
</style>
