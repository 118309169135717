<script lang="ts">
    import TextBox from 'components/form/text-box.svelte'
    import CheckBox from 'components/form/check-box.svelte'
    import Button from '@cucumber/ui/components/buttons/button.svelte'
    import { updateCodes } from '_data/_api/profile.svelte'
    import { authenticatedUserId } from '_auth/stores/auth.store'
    import { getClient } from '@urql/svelte'
    import { signupFormMaxLength } from '_config/constants/signup'

    export let herdCode = ''
    export let participantCode = ''
    export let herdCodeRequired = false
    export let herdCodesArray: string[] = []
    export let herdOriginalCode = ''
    export let noCodeInformation = false
    export let participantCodeRequired = false
    export let participantCodesArray: string[] = []
    export let selectedHerdCode: Maybe<string> = ''
    export let selectedParticipantCode: Maybe<string> = ''

    const client = getClient()
    let herdClicked = false
    let herdCodeDisableClass: string
    let newHerdCode: string = ''
    let newParticipantCode: string = ''
    let participantClicked = false
    let participantCodeDisableClass: string
    let participantOriginalCode = ''
    let showAddNewHerdCode: boolean = false
    let showAddNewParticipantCode: boolean = false
    let apiError = false
    let confirmButtonDisable = false

    function setDefaultCodeSelected(type: string) {
        switch (type) {
            case 'Herd':
                if (herdCodesArray?.length && !noCodeInformation) {
                    selectedHerdCode = herdCodesArray[0] ? herdCodesArray[0] : ''
                    herdCode = selectedHerdCode
                }
                break
            case 'Participant':
                if (participantCodesArray?.length && !noCodeInformation) {
                    selectedParticipantCode = participantCodesArray[0] ? participantCodesArray[0] : ''
                    participantCode = selectedParticipantCode
                }
                break
        }
    }

    function selectCode(type: string, id: string) {
        switch (type) {
            case 'Herd':
                selectedHerdCode = id
                herdCode = id
                break
            case 'Participant':
                selectedParticipantCode = id
                participantCode = id
                break
        }
    }

    function handleCloseClick(type: string) {
        switch (type) {
            case 'Herd':
                newHerdCode = ''
                showAddNewHerdCode = false
                apiError = false
                break
            case 'Participant':
                newParticipantCode = ''
                showAddNewParticipantCode = false
                apiError = false
                break
        }
    }

    async function addCode(type: string) {
        let result: { userUpdateCodes: boolean }
        let newList: string[]

        switch (type) {
            case 'Herd':
                if (herdCodesArray.includes(newHerdCode) || newHerdCode == '') {
                    return
                }

                confirmButtonDisable = true
                newList = [...herdCodesArray]
                newList.push(newHerdCode)

                result = await updateCodes({ subjectId: $authenticatedUserId ? $authenticatedUserId : '', codeType: 'herdCode', codes: JSON.stringify(newList) }, client)

                confirmButtonDisable = false

                if (!result.userUpdateCodes) {
                    newList.pop()
                    apiError = true
                } else {
                    herdCodesArray.push(newHerdCode)
                    selectCode('Herd', newHerdCode)
                    newHerdCode = ''
                    showAddNewHerdCode = false
                    goToSelected(type)
                }
                break
            case 'Participant':
                if (participantCodesArray.includes(newParticipantCode) || newParticipantCode == '') {
                    return
                }

                confirmButtonDisable = true
                newList = [...participantCodesArray]
                newList.push(newParticipantCode)

                result = await updateCodes({ subjectId: $authenticatedUserId ? $authenticatedUserId : '', codeType: 'participantCode', codes: JSON.stringify(newList) }, client)

                confirmButtonDisable = false

                if (!result.userUpdateCodes) {
                    newList.pop()
                    apiError = true
                } else {
                    participantCodesArray.push(newParticipantCode)
                    selectCode('Participant', newParticipantCode)
                    newParticipantCode = ''
                    showAddNewParticipantCode = false
                    goToSelected(type)
                }
                break
        }
    }

    function goToSelected(type: string) {
        switch (type) {
            case 'Herd':
                if (selectedHerdCode !== '') {
                    const element = document.getElementById('herd-code-list')
                    if (element) {
                        setTimeout(() => {
                            const index = herdCodesArray.findIndex(herd => herd === selectedHerdCode)
                            element.scrollTop = index * 30
                        }, 20)
                    }
                }
                break

            case 'Participant':
                if (selectedParticipantCode !== '') {
                    const element = document.getElementById('participant-code-list')
                    if (element) {
                        setTimeout(() => {
                            const index = participantCodesArray.findIndex(participant => participant === selectedParticipantCode)
                            element.scrollTop = index * 30
                        }, 20)
                    }
                }
                break
        }
    }

    $: {
        if (!noCodeInformation && herdClicked) {
            herdCode = herdOriginalCode
            selectedHerdCode = herdOriginalCode
            herdClicked = false
            herdCodeDisableClass = ''
            goToSelected('Herd')
        }

        if (noCodeInformation) {
            herdOriginalCode = herdCode
            herdCode = ''
            herdClicked = true
            herdCodeDisableClass = 'herd-code-list--disabled'
            selectedHerdCode = ''
        }
    }

    $: {
        if (!noCodeInformation && participantClicked) {
            participantCode = participantOriginalCode
            selectedParticipantCode = participantOriginalCode
            participantClicked = false
            participantCodeDisableClass = ''
            goToSelected('Participant')
        }

        if (noCodeInformation) {
            participantOriginalCode = participantCode
            participantCode = ''
            participantClicked = true
            participantCodeDisableClass = 'participant-code-list--disabled'
            selectedParticipantCode = ''
            showAddNewHerdCode = false
            showAddNewParticipantCode = false
        }
    }

    $: if (herdCodesArray?.length == 1) setDefaultCodeSelected('Herd')
    $: if (participantCodesArray?.length == 1) setDefaultCodeSelected('Participant')
</script>

<div class="register-code-information-container">
    <div class="header">Please Select your Dairy Information as part of your auction registration</div>

    {#if herdCodeRequired}
        <div class="subheader">Herd Code</div>

        <div class="herd-code">
            <div id="herd-code-list" class={`herd-code-list ${herdCodeDisableClass}`}>
                <div>
                    {#each herdCodesArray as herd}
                        <div class:selected={selectedHerdCode == herd} on:click={() => selectCode('Herd', herd)}>
                            <div>{herd}</div>

                            <div class="selected-icon" on:click={() => selectCode('Herd', herd)}>
                                {#if selectedHerdCode == herd}
                                    <img src="/assets/images/green-tick.png" alt="account selected" />
                                {:else}
                                    {''}
                                {/if}
                            </div>
                        </div>
                    {:else}
                        <div class="herd-codes-empty">Your saved <strong>Herd Codes</strong> will appear here, you can add new codes with the button on the right</div>
                    {/each}
                </div>
            </div>

            <div>
                {#if !showAddNewHerdCode}
                    <div class="button-add-new">
                        <Button on:click={() => (showAddNewHerdCode = true)} disabled={noCodeInformation} style="outlined button-add-new" size="small" label="Add New Herd Code" />
                    </div>
                {/if}

                {#if showAddNewHerdCode}
                    <div class="herd-code-add-new">
                        <p>
                            Please fill in the field below and click on the confirm button <br />
                            <strong>The Herd Code will be added to your profile</strong>
                        </p>

                        <div class="input-container">
                            <div>
                                <TextBox
                                    required={true}
                                    bind:value={newHerdCode}
                                    disabled={noCodeInformation}
                                    showErrorMessage={false}
                                    id="herd-code"
                                    maxlength={signupFormMaxLength.herdCode}
                                />
                            </div>
                            <div>
                                <Button on:click={() => addCode('Herd')} size="small" disabled={confirmButtonDisable} label="Confirm" />
                            </div>
                            <div>
                                <Button on:click={() => handleCloseClick('Herd')} size="small" style="outlined" label="Cancel" />
                            </div>
                            {#if apiError}
                                <div class="error-message"><small>An error occurred while trying to add the new code, please try again or contact support.</small></div>
                            {/if}
                        </div>

                        {#if herdCodesArray.includes(newHerdCode)}
                            <div class="error-message"><small>Herd code already exists.</small></div>
                        {/if}
                    </div>
                {/if}
            </div>
        </div>
    {/if}

    {#if participantCodeRequired}
        <div class="subheader subheader--top-border">Participant Code</div>

        <div class="participant-code">
            <div id="participant-code-list" class={`participant-code-list ${participantCodeDisableClass}`}>
                <div>
                    {#each participantCodesArray as participant}
                        <div class:selected={selectedParticipantCode == participant} on:click={() => selectCode('Participant', participant)}>
                            <div>{participant}</div>

                            <div class="selected-icon" on:click={() => selectCode('Participant', participant)}>
                                {#if selectedParticipantCode == participant}
                                    <img src="/assets/images/green-tick.png" alt="account selected" />
                                {:else}
                                    {''}
                                {/if}
                            </div>
                        </div>
                    {:else}
                        <div class="participant-codes-empty">
                            Your saved <strong>Participant Codes</strong> will appear here, you can add new codes with the button on the right
                        </div>
                    {/each}
                </div>
            </div>

            <div>
                {#if !showAddNewParticipantCode}
                    <div class="button-add-new">
                        <Button on:click={() => (showAddNewParticipantCode = true)} disabled={noCodeInformation} size="small" style="outlined" label="Add New Participant Code" />
                    </div>
                {/if}

                {#if showAddNewParticipantCode}
                    <div class="participant-code-add-new">
                        <p>
                            Please fill in the field below and click on the confirm button <br />
                            <strong>The Participant Code will be added to your profile</strong>
                        </p>

                        <div class="input-container">
                            <div>
                                <TextBox
                                    required={true}
                                    bind:value={newParticipantCode}
                                    disabled={noCodeInformation}
                                    showErrorMessage={false}
                                    id="participant-code"
                                    maxlength={signupFormMaxLength.participantCode}
                                />
                            </div>

                            <div>
                                <Button on:click={() => addCode('Participant')} size="small" disabled={confirmButtonDisable} label="Confirm" />
                            </div>
                            <div>
                                <Button on:click={() => handleCloseClick('Participant')} size="small" style="outlined" label="Cancel" />
                            </div>
                            {#if apiError}
                                <div class="error-message"><small>An error occurred while trying to add the new code, please try again or contact support.</small></div>
                            {/if}
                        </div>
                        {#if participantCodesArray.includes(newParticipantCode)}
                            <div class="error-message"><small>Participant Code already exists.</small></div>
                        {/if}
                    </div>
                {/if}
            </div>
        </div>
    {/if}

    <div class="no-code-information">
        <span>I don't know this information. Please contact me after the sale to confirm my details </span>
        <CheckBox bind:value={noCodeInformation} id="dairy-info-checkbox" />
        {#if ((herdCodeRequired && herdCode === '') || (participantCodeRequired && participantCode == '')) && !noCodeInformation}
            <div class="error-message"><small>Please select / add your Dairy Information or select the checkbox above </small></div>
        {/if}
    </div>
</div>

<style lang="scss">
    .register-code-information-container {
        display: grid;
        grid-template-rows: repeat(4, max-content);
        margin-top: 2rem;

        div {
            color: var(--color-secondary);
            font-size: var(--font-size);

            &.header {
                background-color: rgba(60, 76, 84, 0.2);
                font-weight: 700;
                padding: 1rem;
            }

            &.subheader {
                background: #f1f1f1;
                font-weight: 700;
                padding: 1rem;
            }

            &.subheader--top-border {
                border-top: solid 1px #d9d9d9;
            }

            &.herd-code,
            &.participant-code {
                align-items: top;
                display: grid;
                gap: 1rem;
                grid-template-columns: 2fr 3fr;
                min-height: 120px;
                padding: 2rem 0 0 0;
                margin-bottom: 1rem;

                @media (max-width: 768px) {
                    padding: 0;
                    grid-template-columns: unset;
                }

                input:hover {
                    background: #fff !important;
                }

                div {
                    @media (max-width: 768px) {
                        :global(input) {
                            font-size: 10px;
                        }
                    }
                }

                .error-message {
                    grid-column: 1/3;
                }

                .herd-code-list,
                .participant-code-list {
                    border: solid 1px #d9d9d9;
                    display: block;
                    grid-template-columns: 1fr;
                    max-height: 130px;
                    overflow-y: auto;
                    padding: 0;

                    @media (max-width: 600px) {
                        max-width: 100%;
                    }

                    & > div {
                        color: var(--color-secondary);

                        & > div {
                            font-size: var(--font-size);

                            &.header {
                                background-color: #fff;
                                display: grid;
                                font-weight: 700;
                                grid-column: 1/5;
                                grid-template-columns: repeat(1) 2rem;
                                padding: 0 1rem;

                                @media (max-width: 1024px) {
                                    grid-template-columns: 1fr 2rem;
                                }

                                div {
                                    padding: 1rem 0;
                                }
                            }

                            &:not(.header, .herd-codes-empty, .participant-codes-empty) {
                                align-items: center;
                                border-top: 1px solid var(--color-grey);
                                cursor: pointer;
                                display: grid;
                                grid-column: 1;
                                grid-gap: 0.5rem;
                                grid-template-columns: 1fr;
                                padding: 0 1rem;
                                text-align: center;
                                word-break: break-word;

                                @media (max-width: 550px) {
                                    grid-gap: 0.5rem;
                                    padding: 0 0.5rem;
                                }

                                div {
                                    padding: 0.5rem 0;

                                    @media (max-width: 550px) {
                                        font-size: 1.3rem;
                                    }

                                    &.red {
                                        color: var(--color-error);
                                    }
                                }
                            }

                            &:not(.header, .herd-codes-empty, .participant-codes-empty):first-child {
                                border: none;
                            }

                            &.selected {
                                background-color: #e0f0d7;
                                border-bottom: 1.9px solid #379530;
                                border-top: 1px solid #379530;

                                img {
                                    transform: translateY(3px);
                                }
                            }

                            .selected-icon {
                                position: absolute;
                                right: 0.5rem;
                                top: 0;
                            }
                        }

                        .herd-codes-empty,
                        .participant-codes-empty {
                            cursor: default;
                            font-size: var(--font-size--small);
                            line-height: 1rem;
                            padding: 1rem;
                            text-align: center;
                        }
                    }
                }

                .herd-code-list--disabled,
                .participant-code-list--disabled {
                    opacity: 0.5;
                    pointer-events: none;
                }

                .button-add-new {
                    margin: 0 0 0 0;
                    float: right;
                    width: 200px;

                    @media (max-width: 600px) {
                        margin-bottom: 1rem;
                        width: 100%;
                    }
                }

                .herd-code-add-new,
                .participant-code-add-new {
                    padding: 0 3rem;
                    position: relative;
                    text-align: left;

                    @media (max-width: 768px) {
                        margin-top: 2rem;
                        padding: 0;
                    }

                    p {
                        color: var(--color-secondary);
                        font-size: var(--font-size--small);
                        line-height: 1rem;
                        margin: 0;
                    }

                    .close {
                        cursor: pointer;
                        position: absolute;
                        right: 0rem;
                        top: -1.5rem;

                        &::before {
                            content: '\f057';
                            font-size: 1.8rem;
                        }

                        @media (max-width: 768px) {
                            top: 0;
                        }
                    }

                    .input-container {
                        align-items: center;
                        display: grid;
                        gap: 1rem;
                        grid-template-columns: 4fr 2fr 1fr;
                        gap: 2rem;
                        justify-content: space-between;

                        @media (max-width: 550px) {
                            gap: 1rem;
                            grid-template-columns: 1fr;
                        }
                    }
                }
            }

            &.participant-code {
                margin: 0;
                padding-bottom: 0;
            }

            &.no-code-information {
                align-items: center;
                padding: 2rem 0 0 0;

                span {
                    margin-right: 1rem;
                }

                .error-message {
                    padding: 0.5rem 0;
                    grid-column: 1/3;
                }
            }

            .error-message {
                color: var(--color-error);
            }

            .error-message-hidden {
                height: 34px;
                visibility: hidden;
            }
        }
    }
</style>
