<script lang="ts">
    export let modalTitle = ''
    export let isCloseModal: boolean

    import { isModalLoading } from '@cucumber/ui/stores/ui.store'
    import LoadingModalBlocker from 'components/modal/modal-blocker.svelte'

    function closeModal() {
        isCloseModal = true
    }
</script>

{#if $isModalLoading}
    <LoadingModalBlocker text="loading" />
{/if}

<div class="modal-header">
    <h4 class="white-text">{modalTitle}</h4>
    <i class="fas close" on:click={closeModal} />
</div>

<div class="modal-body">
    <slot name="body" />
</div>

<div class="modal-action">
    <slot name="action" />
</div>

<style lang="scss">
    .white-text {
        color: var(--color-white);
    }

    .modal-header {
        padding: 1.5rem;
        background-color: var(--color-accent);
        color: var(--color-white);

        display: grid;
        grid-template-columns: 1fr max-content;
        justify-items: center;
        align-items: center;

        @media (max-width: 1024px) {
            width: calc(100vw - 2rem);
        }

        i {
            cursor: pointer;
            align-self: start;
            &.close {
                // right: 1rem;
                // top: 1rem;
                transform: translate(-7px, 15.5px);

                &::before {
                    content: '\f057';
                    font-size: 2.5rem;
                }
            }
        }
    }

    .modal-body {
        color: var(--color-accent);
        padding: 2rem;
        font-size: var(--font-size);

        @media (max-width: 1024px) {
            width: calc(100vw - 2rem);
        }
    }

    .modal-action {
        justify-self: end;
        padding: 0 1rem;
        margin: 0 auto;

        // width: calc(100% - 2rem);
    }
</style>
