<script lang="ts">
    import SignupSuccessModalTemplate from 'components/modal/modal-template.svelte'
    import Button from '@cucumber/ui/components/buttons/button.svelte'
    import { displayModal } from '@cucumber/ui/stores/ui.store'
    import { goto } from '$app/navigation'

    let isCloseModal: boolean

    function closeModal() {
        $displayModal = null
    }

    $: if (isCloseModal) closeModal()
</script>

<SignupSuccessModalTemplate modalTitle="Sign Up Successful" bind:isCloseModal>
    <div slot="body">
        <span>
            Thank you for registering with bidr<sup>®</sup>. You have been sent a verification email which contains a link to complete the registration process. If you do not
            receive an email to your inbox, please check your junk mail folders.
        </span>
    </div>

    <div slot="action">
        <div class="action">
            <div>
                <Button on:click={closeModal} on:click={() => goto('/')} primary={false} style="outlined" label="Close" />
            </div>
        </div>
    </div>
</SignupSuccessModalTemplate>

<style lang="scss">
    .action {
        width: fit-content;

        display: grid;
        grid-auto-flow: column;

        div {
            padding: 1rem;
        }
    }
</style>
