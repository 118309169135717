<script lang="ts">
    import { browser } from '$app/env'
    import { goto } from '$app/navigation'
    import Button from '@cucumber/ui/components/buttons/button.svelte'
    import { displayModal, isLoading, isModalLoading, redirectFromConnect } from '@cucumber/ui/stores/ui.store'
    import { getClient } from '@urql/svelte'
    import { authenticatedUserId, userProfile } from '_auth/stores/auth.store'
    import { BuyerStatus } from '_config/enums/buyer-status.enum'
    import { getAuctionById, getAuctionForHomepage, getUpcomingAuctions } from '_data/_api/auctions/auction.svelte'
    import { auctionRegister, getAuctionAccounts } from '_data/_api/auctions/register.svelte'
    import { getBuyerProfile } from '_data/_api/profile.svelte'
    import RegisterModalTemplate from 'components/modal/modal-template.svelte'
    import { buyerProfileMapping } from 'helpers/mapping/profile.mapping'
    import AgencyAccounts from 'modules/auctions/register/agency-accounts.svelte'
    import DeliveryAddress from 'modules/auctions/register/delivery-address.svelte'
    import DeliveryAddressSelectable from 'modules/auctions/register/delivery-address-selectable.svelte'
    import HerdParticipant from 'modules/auctions/register/herdParticipant.svelte'
    import HerdParticipantSelectable from 'modules/auctions/register/herd-participant-selectable.svelte'
    import RegisterInsurance from 'modules/auctions/register/insurance.svelte'
    import RegisterNAIT from 'modules/auctions/register/nait.svelte'
    import RegisterNAITSelectable from 'modules/auctions/register/nait-selectable.svelte'
    import RegisterWithoutAccounts from 'modules/auctions/register/register-without-accounts.svelte'
    import { activeAuction, auctionAccounts, auctionByIdResponse, auctionFilter, requestAuctionsFromPage } from 'stores/auction.store'
    import { profile } from 'stores/profile.store'
    import { auctionUrl } from 'stores/url.store'
    import { onDestroy } from 'svelte'

    const client = getClient()
    let afterLoggedIn: string | null
    let auctionRegisterAccounts: AuctionAccountsResponse
    let auctionRegisterResponse: AuctionRegisterResponse
    let buyerProfileId: string
    let buyerProfileViewModel: BuyerProfileViewModel
    let canGoStepOne: boolean = false
    let canRegister: boolean = false
    let deliveryAddress = { noAddressInformation: false, address1: '', address2: '', city: '', post: '' }
    let deliveryAddressesArray: AddressInformation[] = []
    let haveAccounts: boolean
    let herdCodesArray: string[] = []
    let herdParticipant = { noCodeInformation: false, herdCode: '', participantCode: '' }
    let insurance = { selectedInsurance: '', otherInsurance: '' }
    let insuranceMessage: string
    let isCloseModal: boolean
    let isOnlyOneStep: boolean = true
    let isRegistered = false
    let nait = { noNait: false, naitNumber: '', isNaitNumberValid: false }
    let naitNumbersArray: string[] = []
    let participantCodesArray: string[] = []
    let registerButtonLabel = $redirectFromConnect ? 'Register and Connect' : 'Register'
    let selectedAccountId: number | null
    let selectedAccountStatus: number | null
    let selectedHerdCode: string | null
    let selectedNait: string | null
    let selectedParticipantCode: string | null
    let step: number = 1

    if (browser) {
        $auctionAccounts = null
        afterLoggedIn = localStorage.getItem('after_logged_in')

        checkUserProfile()

        if (!afterLoggedIn) {
            if (!$activeAuction?.isRegistered) {
                apiGetAuctionAccounts(refreshAuctionAccounts)
            }
        } else {
            apiGetAuctionDetail(checkIfRegistered)
        }
    }

    function checkUserProfile() {
        if ($userProfile?.role?.key.toString() == 'Buyer') {
            buyerProfileId = $authenticatedUserId ?? ''
            apiGetBuyerProfile()
        }
    }

    async function apiGetBuyerProfile() {
        $profile = await getBuyerProfile(buyerProfileId, client)
        initProfile()
    }

    function initProfile() {
        buyerProfileViewModel = buyerProfileMapping($profile) as BuyerProfileViewModel

        if (buyerProfileViewModel !== null) {
            naitNumbersArray =
                buyerProfileViewModel.otherInformation.value.naitNumbers !== '' ? JSON.parse(buyerProfileViewModel.otherInformation.value.naitNumbers) : naitNumbersArray

            herdCodesArray = buyerProfileViewModel.otherInformation.value.herdCodes !== '' ? JSON.parse(buyerProfileViewModel.otherInformation.value.herdCodes) : herdCodesArray

            participantCodesArray =
                buyerProfileViewModel.otherInformation.value.participantCodes !== ''
                    ? JSON.parse(buyerProfileViewModel.otherInformation.value.participantCodes)
                    : participantCodesArray

            deliveryAddressesArray =
                buyerProfileViewModel.otherInformation.value.deliveryAddresses !== ''
                    ? JSON.parse(buyerProfileViewModel.otherInformation.value.deliveryAddresses)
                    : deliveryAddressesArray
        }
    }

    onDestroy(() => {
        $redirectFromConnect = false
        $auctionAccounts = null
    })

    function refreshAuctionAccounts() {
        auctionRegisterAccounts = $auctionAccounts as AuctionAccountsResponse
        haveAccounts = $auctionAccounts?.accounts && $auctionAccounts?.accounts?.length > 0 ? true : false
        insuranceMessage = $auctionAccounts?.insuranceMessage as string

        checkIsOnlyOneStep()
    }

    function checkIsOnlyOneStep() {
        if (auctionRegisterAccounts.herdCodeRequired || auctionRegisterAccounts.participantCodeRequired || auctionRegisterAccounts.deliveryAddressRequired) {
            isOnlyOneStep = false
        }
    }

    function validateNaitInformation() {
        const agencyAccountValidation = haveAccounts && selectedAccountId != undefined && selectedAccountStatus == BuyerStatus.Approved
        const naitValidation = !auctionRegisterAccounts?.naitRegistrationRequired || (nait?.naitNumber?.length > 0 && nait.isNaitNumberValid) || nait.noNait
        const insuranceValidation = !auctionRegisterAccounts?.insuranceRequired || insurance.selectedInsurance?.length > 0
        return agencyAccountValidation && naitValidation && insuranceValidation
    }

    function validateHerdParticipantAddressInformation() {
        const herdValidation =
            !auctionRegisterAccounts?.herdCodeRequired || (auctionRegisterAccounts?.herdCodeRequired && herdParticipant.herdCode != '') || herdParticipant.noCodeInformation
        const participationValidation =
            !auctionRegisterAccounts?.participantCodeRequired ||
            (auctionRegisterAccounts?.participantCodeRequired && herdParticipant.participantCode != '') ||
            herdParticipant.noCodeInformation
        const addressValidation =
            !auctionRegisterAccounts?.deliveryAddressRequired ||
            (deliveryAddress.address1 != '' && deliveryAddress.city != '' && Number(deliveryAddress.post) > 0 && deliveryAddress.post.toString().length == 4) ||
            deliveryAddress.noAddressInformation
        return canGoStepOne && herdValidation && participationValidation && addressValidation
    }

    function checkHerdParticipantAndAddress() {
        canGoStepOne = validateNaitInformation()
    }

    function checkRegisterNait() {
        if (isOnlyOneStep) {
            canRegister = validateNaitInformation()
        } else {
            canRegister = validateHerdParticipantAddressInformation()
        }
    }

    async function checkIfRegistered() {
        isRegistered = $auctionByIdResponse?.bidAccounts && $auctionByIdResponse?.bidAccounts.length > 0 ? true : false
        $isLoading = false

        if (isRegistered) {
            $displayModal = null
        } else {
            apiGetAuctionAccounts(refreshAuctionAccounts)
        }
    }

    function closeModal() {
        $displayModal = null
    }

    //api functions
    async function apiGetAuctionDetail(callback: Function) {
        $auctionByIdResponse = await getAuctionById($activeAuction?.auctionId as number, client)

        localStorage.removeItem('after_logged_in')

        callback()
    }

    async function apiGetAuctionAccounts(callback: Function) {
        $auctionAccounts = await getAuctionAccounts($activeAuction?.auctionId as number, client)

        callback()
    }

    function convertDeliveryAddress() {
        let address = `${deliveryAddress.address1}`
        address = deliveryAddress.address2 !== '' ? `${address}, ${deliveryAddress.address2}` : address
        address = `${address}, ${deliveryAddress.city}, ${deliveryAddress.post}`

        return {
            addressLine1: deliveryAddress.address1,
            addressLine2: deliveryAddress.address2,
            city: deliveryAddress.city,
            postCode: deliveryAddress.post,
            fullAddress: address,
        }
    }

    function goBack() {
        step = 1
    }

    async function apiHandleRegister() {
        $isModalLoading = true

        const deliveryAddressConverted = convertDeliveryAddress()

        const auctionRegisterRequest = {
            auctionId: auctionRegisterAccounts?.auctionId,
            userAgencyAccountId: selectedAccountId,
            otherInsurance: insurance.otherInsurance,
            insuranceNomination: insurance.selectedInsurance,
            naitNumber: nait.naitNumber,
            herdCode: herdParticipant.herdCode,
            participantCode: herdParticipant.participantCode,
            deliveryAddress: JSON.stringify(deliveryAddressConverted),
        } as AuctionRegisterRequest

        auctionRegisterResponse = await auctionRegister(auctionRegisterRequest, client)

        if (auctionRegisterResponse) {
            selectedAccountId = null
            $isModalLoading = false

            setTimeout(() => {
                if ($redirectFromConnect) {
                    closeModal()
                    //redirect to auction bid
                    goto(`${$auctionUrl}/auctions/${$activeAuction?.auctionId}/`)
                } else {
                    apiRefreshAuctions()
                }
            })
        }
    }

    function modalHandleStep() {
        step = 2
    }

    async function apiRefreshAuctions() {
        if ($auctionFilter) {
            if ($requestAuctionsFromPage == 'home') getAuctionForHomepage($auctionFilter, client)
            else if ($requestAuctionsFromPage == 'upcoming') getUpcomingAuctions($auctionFilter, client)
        }

        if ($requestAuctionsFromPage == 'auctiondetails') $auctionByIdResponse = await getAuctionById($activeAuction?.auctionId as number, client)
    }

    $: if (isOnlyOneStep && (selectedAccountId || nait || insurance) && step == 1) checkRegisterNait()

    $: if (!isOnlyOneStep && (selectedAccountId || nait || insurance) && step == 1) checkHerdParticipantAndAddress()

    $: if (!isOnlyOneStep && (herdParticipant || deliveryAddress) && step == 2) checkRegisterNait()

    $: if (isCloseModal) closeModal()

    $: if ($userProfile && $userProfile?.role?.key.toString() == 'Buyer') {
        checkUserProfile()
    }
</script>

{#if $auctionAccounts}
    {#if !haveAccounts}
        <RegisterWithoutAccounts />
    {:else}
        <RegisterModalTemplate modalTitle="Register for Auction" bind:isCloseModal>
            <div slot="body">
                {#if !auctionRegisterResponse}
                    {#if step === 1}
                        <AgencyAccounts data={auctionRegisterAccounts} bind:selectedAccountId bind:selectedAccountStatus />
                    {/if}
                    {#if auctionRegisterAccounts?.naitRegistrationRequired && step === 1}
                        {#if $userProfile?.role?.key.toString() == 'Agent'}
                            <RegisterNAIT bind:noNait={nait.noNait} bind:naitNumber={nait.naitNumber} bind:isNaitNumberValid={nait.isNaitNumberValid} />
                        {:else}
                            <RegisterNAITSelectable
                                bind:selectedNait
                                bind:naitNumbersArray
                                bind:noNait={nait.noNait}
                                bind:naitNumber={nait.naitNumber}
                                bind:isNaitNumberValid={nait.isNaitNumberValid}
                            />
                        {/if}
                    {/if}
                    {#if auctionRegisterAccounts?.insuranceRequired && step === 1}
                        <RegisterInsurance message={insuranceMessage} bind:selectedInsurance={insurance.selectedInsurance} bind:otherInsurance={insurance.otherInsurance} />
                    {/if}
                    {#if auctionRegisterAccounts?.deliveryAddressRequired && step === 2}
                        {#if $userProfile?.role?.key.toString() == 'Agent'}
                            <DeliveryAddress
                                bind:noAddressInformation={deliveryAddress.noAddressInformation}
                                bind:address1={deliveryAddress.address1}
                                bind:address2={deliveryAddress.address2}
                                bind:city={deliveryAddress.city}
                                bind:post={deliveryAddress.post}
                            />
                        {:else}
                            <DeliveryAddressSelectable
                                bind:noAddressInformation={deliveryAddress.noAddressInformation}
                                bind:address1={deliveryAddress.address1}
                                bind:address2={deliveryAddress.address2}
                                bind:city={deliveryAddress.city}
                                bind:post={deliveryAddress.post}
                                bind:deliveryAddressesArray
                            />
                        {/if}
                    {/if}
                    {#if (auctionRegisterAccounts?.herdCodeRequired || auctionRegisterAccounts?.participantCodeRequired) && step === 2}
                        {#if $userProfile?.role?.key.toString() == 'Agent'}
                            <HerdParticipant
                                bind:noCodeInformation={herdParticipant.noCodeInformation}
                                bind:herdCode={herdParticipant.herdCode}
                                bind:participantCode={herdParticipant.participantCode}
                                bind:herdCodeRequired={auctionRegisterAccounts.herdCodeRequired}
                                bind:participantCodeRequired={auctionRegisterAccounts.participantCodeRequired}
                            />
                        {:else}
                            <HerdParticipantSelectable
                                bind:noCodeInformation={herdParticipant.noCodeInformation}
                                bind:herdCode={herdParticipant.herdCode}
                                bind:participantCode={herdParticipant.participantCode}
                                bind:herdCodeRequired={auctionRegisterAccounts.herdCodeRequired}
                                bind:participantCodeRequired={auctionRegisterAccounts.participantCodeRequired}
                                bind:herdCodesArray
                                bind:participantCodesArray
                                bind:selectedHerdCode
                                bind:selectedParticipantCode
                            />
                        {/if}
                    {/if}
                {:else if !$redirectFromConnect}
                    <div
                        class="thankyou-message"
                        style="
                    text-align: center;"
                    >
                        Thank you for registering for the {auctionRegisterAccounts.auctionTitle}
                    </div>
                {/if}
            </div>

            <div slot="action">
                <div class="action">
                    {#if !auctionRegisterResponse}
                        {#if step === 2}
                            <div><Button on:click={goBack} label="Back" style="outlined" /></div>
                        {/if}
                        <div>
                            <Button on:click={closeModal} style="outlined" label="Cancel" />
                        </div>
                        {#if step === 1 && !isOnlyOneStep}
                            <div>
                                <Button disabled={!canGoStepOne} on:click={modalHandleStep} label="Next" />
                            </div>
                        {/if}
                        {#if step === 2 || isOnlyOneStep}
                            <div>
                                <Button disabled={!canRegister} on:click={apiHandleRegister} label={registerButtonLabel} />
                            </div>
                        {/if}
                    {:else}
                        <div>
                            <Button on:click={() => ($displayModal = null)} style="outlined" label="Close" />
                        </div>
                    {/if}
                </div>
            </div>
        </RegisterModalTemplate>
    {/if}
{/if}

<style lang="scss">
    .action {
        width: fit-content;

        display: grid;
        grid-auto-flow: column;

        @media (max-width: 400px) {
            grid-auto-flow: unset;
            :global(input) {
                font-size: 10px;
            }
        }

        div {
            padding: 1rem;
        }
    }
</style>
