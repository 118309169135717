<script lang="ts">
    import Button from '@cucumber/ui/components/buttons/button.svelte'
    import { auctionAccount } from '_config/constants/signup'
    import CheckBox from 'components/form/check-box.svelte'
    import TextBox from 'components/form/text-box.svelte'
    import { updateCodes } from '_data/_api/profile.svelte'
    import { getClient } from '@urql/svelte'
    import { authenticatedUserId } from '_auth/stores/auth.store'
    import { numbersOnlyRegex } from '_config/constants/regex'
    import { browser } from '$app/env'

    export let address1: string
    export let address2: string
    export let city: string
    export let noAddressInformation: boolean = false
    export let post: number | string
    export let deliveryAddressesArray: AddressInformation[] = []

    const client = getClient()
    let selectedDeliveryAddress: AddressInformation | null
    let address1Original: string
    let address2Original: string
    let addressClicked = false
    let cityOriginal: string
    let postOriginal: number | string
    let addressDisableClass: string
    let newAddress: AddressInformation = {
        address1: '',
        address2: '',
        city: '',
        postalCode: '',
        error: {
            address1: false,
            address2: false,
            city: false,
            postalCode: false,
        },
    }
    let showAddNewAddress: boolean = false
    let selectedAddressIndex: number = -1
    let apiError = false
    let confirmButtonDisable = false

    if (browser) {
        setSelectedAddress()
    }

    function setSelectedAddress() {
        if (address1 !== '' && city !== '' && post.toString().length >= 4) {
            selectedDeliveryAddress = {
                address1: address1,
                address2: address2,
                city: city,
                postalCode: post,
                error: {
                    address1: false,
                    address2: false,
                    city: false,
                    postalCode: false,
                },
            }

            goToSelected()
        }
    }

    function setDefaultAddressSelected() {
        if (deliveryAddressesArray?.length && !noAddressInformation) {
            selectAddress(0)
        }
    }

    function selectAddress(index: number) {
        if (index < 0) return

        selectedDeliveryAddress = deliveryAddressesArray[index]
        address1 = deliveryAddressesArray[index].address1
        address2 = deliveryAddressesArray[index].address2
        city = deliveryAddressesArray[index].city
        post = deliveryAddressesArray[index].postalCode
        selectedAddressIndex = index
    }

    function handleCloseClick() {
        showAddNewAddress = false
    }

    async function addAddress() {
        if (newAddress.address1 === '' || newAddress.city === '' || newAddress.postalCode.toString().length < 4) {
            return
        }

        if (deliveryAddressesArray.includes(newAddress)) {
            return
        }

        confirmButtonDisable = true
        const newList = [...deliveryAddressesArray]
        newList.push(newAddress)

        const result = await updateCodes({ subjectId: $authenticatedUserId ? $authenticatedUserId : '', codeType: 'deliveryAddress', codes: JSON.stringify(newList) }, client)

        confirmButtonDisable = false

        if (!result.userUpdateCodes) {
            newList.pop()
            apiError = true
        } else {
            deliveryAddressesArray.push(newAddress)
            newAddress = {
                address1: '',
                address2: '',
                city: '',
                postalCode: '',
                error: {
                    address1: false,
                    address2: false,
                    city: false,
                    postalCode: false,
                },
            }
            showAddNewAddress = false
            selectAddress(deliveryAddressesArray.length - 1)
            setTimeout(() => {
                goToSelected()
            }, 20)
        }
    }

    function goToSelected() {
        if (selectedDeliveryAddress !== null) {
            const element = document.getElementById('delivery-address-list')

            const index = deliveryAddressesArray.findIndex(
                address =>
                    address.address1 === selectedDeliveryAddress?.address1 &&
                    address.city === selectedDeliveryAddress?.city &&
                    address.postalCode === selectedDeliveryAddress?.postalCode
            )

            selectedAddressIndex = index

            if (element) {
                setTimeout(() => {
                    element.scrollTop = index * 30
                }, 20)
            }
        }
    }

    $: {
        if (!noAddressInformation && addressClicked) {
            address1 = address1Original
            address2 = address2Original
            city = cityOriginal
            post = postOriginal
            addressClicked = false
            addressDisableClass = ''
            selectedDeliveryAddress = {
                address1: address1Original,
                address2: address2Original,
                city: cityOriginal,
                postalCode: postOriginal,
                error: {
                    address1: false,
                    address2: false,
                    city: false,
                    postalCode: false,
                },
            }

            selectAddress(
                deliveryAddressesArray.findIndex(address => address.address1 === address1Original && address.city === cityOriginal && address.postalCode === postOriginal)
            )

            setTimeout(() => {
                goToSelected()
            }, 20)
        }

        if (noAddressInformation) {
            address1Original = address1
            address1 = ''
            address2Original = address2
            address2 = ''
            cityOriginal = city
            city = ''
            postOriginal = post
            post = ''
            addressClicked = true
            addressDisableClass = 'delivery-address-list--disabled'
            selectedDeliveryAddress = null
            showAddNewAddress = false
            selectedAddressIndex = -1
        }
    }

    $: post = post.toString().replace(/[^0-9]/g, '')
    $: newAddress.postalCode = newAddress.postalCode.toString().replace(/[^0-9]/g, '')
    $: if (deliveryAddressesArray?.length === 1) setDefaultAddressSelected()
</script>

<div class="register-delivery-address-container">
    <div class="header">
        Please Select your Delivery Address for Transport Arrangements <div class="c" />
    </div>

    <div class="delivery-address">
        {#if !showAddNewAddress}
            <div id="delivery-address-list" class={`delivery-address-list ${addressDisableClass}`}>
                <div>
                    {#if deliveryAddressesArray}
                        {#each deliveryAddressesArray as address, index}
                            <div class:selected={selectedAddressIndex === index} on:click={() => selectAddress(index)}>
                                <div>
                                    <small>
                                        {address.address1}
                                        {#if address.address2}, {address.address2}{/if},
                                        {address.city},
                                        {address.postalCode}
                                    </small>
                                </div>

                                <div class="selected-icon" on:click={() => selectAddress(index)}>
                                    {#if selectedAddressIndex == index}
                                        <img src="/assets/images/green-tick.png" alt="account selected" />
                                    {:else}
                                        {''}
                                    {/if}
                                </div>
                            </div>
                        {:else}
                            <div class="delivery-address-empty">
                                Your saved <strong>Delivery Addresses</strong> will appear here, you can add new addresses with the button below
                            </div>
                        {/each}
                    {/if}
                </div>
            </div>
        {/if}

        <div>
            {#if showAddNewAddress}
                <div class="delivery-address-add-new-message">
                    <p>
                        Please fill in the fields below and click on the confirm button <br />
                        <strong>The Delivery Address will be added to your profile</strong>
                    </p>
                </div>

                <div class="delivery-address-add-new">
                    <div class="address-item">
                        <div class="address-label">Address Line 1</div>
                        <div>
                            <TextBox
                                required={true}
                                bind:value={newAddress.address1}
                                disabled={noAddressInformation}
                                showErrorMessage={false}
                                maxlength={auctionAccount.address1}
                                id="address-1"
                            />
                        </div>
                    </div>

                    <div class="address-item">
                        <div class="address-label">City/Town</div>
                        <div>
                            <TextBox
                                required={true}
                                bind:value={newAddress.city}
                                disabled={noAddressInformation}
                                showErrorMessage={false}
                                maxlength={auctionAccount.city}
                                id="address-city"
                            />
                        </div>
                    </div>

                    <div class="address-item">
                        <div class="address-label">Address Line 2</div>
                        <div>
                            <TextBox
                                required={false}
                                bind:value={newAddress.address2}
                                disabled={noAddressInformation}
                                showErrorMessage={false}
                                maxlength={auctionAccount.address2}
                                id="address-2"
                            />
                        </div>
                    </div>

                    <div class="address-item">
                        <div class="address-label">Post Code</div>
                        <div>
                            <TextBox
                                required={true}
                                bind:value={newAddress.postalCode}
                                disabled={noAddressInformation}
                                showErrorMessage={false}
                                maxlength={auctionAccount.post}
                                pattern={numbersOnlyRegex}
                                id="address-postcode"
                            />

                            {#if newAddress.postalCode.length > 0 && newAddress.postalCode.length < 4}
                                <div class="error-message">Minimum length 4 characters</div>
                            {/if}
                        </div>
                    </div>

                    {#if apiError}
                        <div class="error-message"><small>An error occurred while trying to add the new address, please try again or contact support.</small></div>
                    {/if}
                </div>

                <div class="input-container">
                    <div>
                        <Button on:click={() => addAddress()} size="small" disabled={confirmButtonDisable} label="Confirm" />
                    </div>
                    <div>
                        <Button on:click={handleCloseClick} size="small" style="outlined" label="Cancel" />
                    </div>
                </div>
            {/if}
        </div>
    </div>

    <div class="no-code-information">
        <div>
            <span>Please contact me after the sale to confirm my details</span>
            <CheckBox bind:value={noAddressInformation} id="address-checkbox" />
            {#if !noAddressInformation && address1 === ''}
                <div class="error-message"><small>Please select / add your Delivery Address or select the checkbox above </small></div>
            {/if}
        </div>

        <div>
            {#if !showAddNewAddress}
                <div class="button-add-new">
                    <Button
                        on:click={() => (showAddNewAddress = true)}
                        disabled={noAddressInformation}
                        style="outlined button-add-new"
                        size="small"
                        label="Add New Delivery Address"
                    />
                </div>
            {/if}
        </div>
    </div>
</div>

<style lang="scss">
    .register-delivery-address-container {
        display: grid;
        grid-template-rows: repeat(4, max-content);
        margin-top: 2rem;

        div {
            color: var(--color-secondary);
            font-size: var(--font-size);

            &.header {
                background-color: rgba(60, 76, 84, 0.2);
                font-weight: 700;
                padding: 1rem;
            }

            &.delivery-address {
                display: grid;
                grid-template-columns: 1fr;
                align-items: center;
                padding: 0;
                border-top: 1px solid var(--color-grey);
                width: 100%;

                @media (max-width: 768px) {
                    grid-template-columns: unset;
                }

                input:hover {
                    background: #fff !important;
                }

                div {
                    @media (max-width: 400px) {
                        :global(input) {
                            font-size: 10px;
                        }
                    }
                }

                .error-message {
                    grid-column: 1/3;
                }

                .delivery-address-list {
                    border: solid 1px #d9d9d9;
                    display: block;
                    grid-template-columns: 1fr;
                    max-height: 120px;
                    overflow-y: auto;
                    padding: 0;

                    @media (max-width: 600px) {
                        max-width: 100%;
                    }

                    & > div {
                        color: var(--color-secondary);

                        & > div {
                            font-size: var(--font-size);
                            &.header {
                                background-color: #fff;
                                display: grid;
                                font-weight: 700;
                                grid-column: 1/5;
                                grid-template-columns: repeat(1) 2rem;
                                padding: 0 1rem;

                                @media (max-width: 1024px) {
                                    grid-template-columns: 1fr 2rem;
                                }

                                div {
                                    padding: 1rem 0;
                                }
                            }

                            &:not(.header, .delivery-address-empty) {
                                align-items: center;
                                border-top: 1px solid var(--color-grey);
                                cursor: pointer;
                                display: grid;
                                grid-column: 1;
                                grid-gap: 0.5rem;
                                grid-template-columns: 1fr;
                                padding: 0 1rem;
                                text-align: left;
                                word-break: break-word;

                                @media (max-width: 550px) {
                                    grid-gap: 0.5rem;
                                    padding: 0 0.5rem;
                                }

                                div {
                                    padding: 0.5rem 0;

                                    @media (max-width: 550px) {
                                        font-size: 1.3rem;
                                    }

                                    &.red {
                                        color: var(--color-error);
                                    }
                                }
                            }

                            &:not(.header, .delivery-address-empty):first-child {
                                border: none;
                            }

                            &.selected {
                                background-color: #e0f0d7;
                                border-bottom: 1.9px solid #379530;
                                border-top: 1px solid #379530;

                                img {
                                    transform: translateY(3px);
                                }
                            }

                            .selected-icon {
                                position: absolute;
                                right: 0.5rem;
                                top: 0;
                            }
                        }

                        .delivery-address-empty {
                            cursor: default;
                            font-size: var(--font-size--small);
                            line-height: 1rem;
                            padding: 1rem;
                            text-align: center;
                        }
                    }
                }

                .delivery-address-list--disabled {
                    opacity: 0.5;
                    pointer-events: none;
                }

                .delivery-address-add-new-message {
                    margin: 1rem 0;
                    padding: 0 1rem;

                    p {
                        color: var(--color-secondary);
                        font-size: var(--font-size--small);
                        line-height: 1rem;
                        margin: 0;
                    }
                }

                .delivery-address-add-new {
                    display: grid;
                    grid-template-columns: 3fr 2fr;
                    align-items: top;
                    position: relative;
                    text-align: center;
                    padding: 0 2rem;

                    @media (max-width: 550px) {
                        grid-template-columns: 1fr;
                        padding-left: 0;
                    }

                    .address-item {
                        display: grid;
                        grid-template-columns: 1fr;
                        align-items: center;
                        padding: 0.5rem;
                        text-align: left;

                        @media (max-width: 768px) {
                            grid-template-columns: unset;
                        }

                        .error-message {
                            color: var(--color-error);
                            font-size: var(--font-size--small);
                            font-style: italic;
                        }
                    }

                    .close {
                        cursor: pointer;
                        position: absolute;
                        right: 0rem;
                        top: -4.5rem;

                        &::before {
                            content: '\f057';
                            font-size: 1.8rem;
                        }

                        @media (max-width: 550px) {
                            top: -2rem;
                        }
                    }
                }

                .input-container {
                    align-items: center;
                    display: grid;
                    margin: 1rem 3rem 0 0;
                    float: right;
                    width: 250px;
                    grid-template-columns: 1fr 1fr;
                    gap: 2rem;
                    justify-content: space-between;

                    @media (max-width: 550px) {
                        gap: 1rem;
                        grid-template-columns: 1fr 1fr;
                        justify-content: center;
                    }
                }
            }

            &.no-code-information {
                display: grid;
                grid-template-columns: 4fr 2fr;
                align-items: center;
                padding: 2rem 0 1rem 0;

                @media (max-width: 600px) {
                    grid: unset;
                }

                div {
                    padding-right: 0;
                }

                span {
                    margin-right: 1rem;
                }

                .error-message {
                    padding: 0.5rem 0;
                    grid-column: 1/3;
                }

                .button-add-new {
                    margin: 0 0 0 0;
                    float: right;
                    width: 200px;

                    @media (max-width: 600px) {
                        margin-top: 1rem;
                        width: 100%;
                    }
                }
            }

            .error-message {
                color: var(--color-error);
            }

            .error-message-hidden {
                height: 34px;
                visibility: hidden;
            }
        }
    }
</style>
