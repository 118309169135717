<script lang="ts">
    import TextBox from 'components/form/text-box.svelte'
    import CheckBox from 'components/form/check-box.svelte'
    import Button from '@cucumber/ui/components/buttons/button.svelte'
    import { updateCodes } from '_data/_api/profile.svelte'
    import { authenticatedUserId } from '_auth/stores/auth.store'
    import { getClient } from '@urql/svelte'
    import { auctionAccount } from '_config/constants/signup'

    export let checkClicked = false
    export let isNaitNumberValid = false
    export let naitNumber = ''
    export let naitNumbersArray: string[] = []
    export let naitOriginalNumber = ''
    export let noNait = false
    export let selectedNait: Maybe<string>

    const client = getClient()
    let apiError = false
    let confirmButtonDisable = false
    let naitDisableClass: string
    let newNaitNumber: string = ''
    let selectedNaitOriginal: Maybe<string>
    let showAddNewNaitNumber = false

    function naitNumberValidation() {
        isNaitNumberValid = newNaitNumber.match(/^[0-9]{2,6}$|^[0-9]{8}$/) != null
    }

    function setDefaultNaitSelected() {
        if (naitNumbersArray?.length && !noNait) {
            selectedNait = naitNumbersArray[0] ? naitNumbersArray[0] : ''
            naitNumber = selectedNait
            isNaitNumberValid = true
        }
    }

    function selectNait(id: string) {
        selectedNait = id

        naitNumber = ''
        naitNumber = id
        isNaitNumberValid = true
    }

    function handleCloseClick() {
        showAddNewNaitNumber = false
    }

    async function addNaitNumber() {
        if (newNaitNumber == '' || !isNaitNumberValid) {
            return
        }

        if (naitNumbersArray.includes(newNaitNumber)) {
            isNaitNumberValid = true
            return
        }

        confirmButtonDisable = true
        isNaitNumberValid = true
        const newList = [...naitNumbersArray]
        newList.push(newNaitNumber)

        const result = await updateCodes({ subjectId: $authenticatedUserId ? $authenticatedUserId : '', codeType: 'naitNumber', codes: JSON.stringify(newList) }, client)

        confirmButtonDisable = false

        if (!result.userUpdateCodes) {
            newList.pop()
            apiError = true
        } else {
            naitNumbersArray.push(newNaitNumber)
            selectNait(newNaitNumber)
            newNaitNumber = ''
            showAddNewNaitNumber = false
            goToSelected()
        }
    }

    function goToSelected() {
        if (selectedNait !== '') {
            const element = document.getElementById('nait-number-list')
            if (element) {
                setTimeout(() => {
                    const index = naitNumbersArray.findIndex(nait => nait === selectedNait)
                    element.scrollTop = index * 30
                }, 20)
            }
        }
    }

    $: {
        if (!noNait && checkClicked) {
            naitNumber = naitOriginalNumber
            checkClicked = false
            selectedNait = selectedNaitOriginal
            naitDisableClass = ''
            goToSelected()
        }

        if (noNait) {
            naitOriginalNumber = naitNumber
            naitNumber = ''
            checkClicked = true
            selectedNaitOriginal = selectedNait
            selectedNait = ''
            naitDisableClass = 'nait-number-list--disabled'
            showAddNewNaitNumber = false
        }
    }

    $: if (naitNumbersArray?.length == 1) setDefaultNaitSelected()
</script>

<div class="register-nait-container">
    <div class="header">Please Select your NAIT number as part of your auction registration</div>

    <div class="nait-number">
        <div id="nait-number-list" class={`nait-number-list ${naitDisableClass}`}>
            <div>
                {#each naitNumbersArray as nait}
                    <div class:selected={selectedNait == nait} on:click={() => selectNait(nait)}>
                        <div>{nait}</div>

                        <div class="selected-icon" on:click={() => selectNait(nait)}>
                            {#if selectedNait == nait}
                                <img src="/assets/images/green-tick.png" alt="account selected" />
                            {:else}
                                {''}
                            {/if}
                        </div>
                    </div>
                {:else}
                    <div class="nait-numbers-empty">Your saved NAIT numbers will appear here</div>
                {/each}
            </div>
        </div>

        <div>
            {#if !showAddNewNaitNumber}
                <div class="button-add-new">
                    <Button on:click={() => ((showAddNewNaitNumber = true), (apiError = false))} disabled={noNait} size="small" style="outlined" label="Add New NAIT Number" />
                </div>
            {/if}

            {#if showAddNewNaitNumber}
                <div class="nait-number-add-new">
                    <p>
                        Please fill in the field below and click on the confirm button <br />
                        <strong>The NAIT Number will be added to your profile</strong>
                    </p>

                    <div class="input-container">
                        <div>
                            <TextBox
                                isError={!isNaitNumberValid}
                                required={true}
                                bind:value={newNaitNumber}
                                disabled={noNait}
                                on:input={naitNumberValidation}
                                showErrorMessage={false}
                                id="nait-number"
                                maxlength={auctionAccount.naitNumber}
                            />
                        </div>

                        <div>
                            <Button on:click={() => addNaitNumber()} size="small" disabled={!isNaitNumberValid || confirmButtonDisable} label="Confirm" />
                        </div>

                        <div>
                            <Button on:click={handleCloseClick} size="small" style="outlined" label="Cancel" />
                        </div>
                        {#if apiError}
                            <div class="error-message"><small>An error occurred while trying to add the new number, please try again or contact support.</small></div>
                        {/if}
                    </div>
                    {#if newNaitNumber != '' && !isNaitNumberValid}
                        <div class="error-message"><small>Incorrect NAIT number format.</small></div>
                    {/if}

                    {#if naitNumbersArray.includes(newNaitNumber)}
                        <div class="error-message"><small>NAIT number already exists.</small></div>
                    {/if}
                </div>
            {/if}
        </div>
    </div>

    <div class="no-nait">
        <span>I don't know my NAIT number. Please contact me after the sale to confirm my details</span>
        <CheckBox bind:value={noNait} id="nait-checkbox" />
        {#if naitNumber == '' && !noNait}
            <div class="error-message"><small>Please select / add your NAIT Number or select the checkbox above.</small></div>
        {/if}
    </div>
</div>

<style lang="scss">
    .register-nait-container {
        background-color: #f5f5f5;
        display: grid;
        grid-template-rows: repeat(2, max-content);
        margin-top: 2rem;
        min-height: 140px;

        div {
            color: var(--color-secondary);
            font-size: var(--font-size);

            &.header {
                background-color: rgba(60, 76, 84, 0.2);
                font-weight: 700;
                padding: 1rem;
            }

            &.nait-number {
                align-items: center;
                border-top: 1px solid var(--color-grey);
                display: grid;
                gap: 1rem;
                grid-template-columns: 2fr 3fr;
                padding: 2rem;

                @media (max-width: 400px) {
                    grid-template-columns: unset;
                }

                @media (max-width: 1024px) {
                    grid-template-columns: 1fr;
                }

                .nait-number-list {
                    border: solid 1px #d9d9d9;
                    display: block;
                    grid-template-columns: 1fr;
                    max-height: 120px;
                    overflow-y: auto;

                    @media (max-width: 600px) {
                        max-width: 100%;
                    }

                    & > div {
                        color: var(--color-secondary);

                        & > div {
                            font-size: var(--font-size);

                            &.header {
                                background-color: #fff;
                                display: grid;
                                font-weight: 700;
                                grid-column: 1/5;
                                grid-template-columns: repeat(1) 2rem;
                                padding: 0 1rem;

                                @media (max-width: 1024px) {
                                    grid-template-columns: 1fr 2rem;
                                }

                                div {
                                    padding: 1rem 0;
                                }
                            }

                            &:not(.header, .nait-numbers-empty) {
                                align-items: center;
                                border-top: 1px solid var(--color-grey);
                                cursor: pointer;
                                display: grid;
                                grid-column: 1;
                                grid-gap: 0.5rem;
                                grid-template-columns: 1fr;
                                padding: 0 1rem;
                                text-align: center;
                                word-break: break-word;

                                @media (max-width: 550px) {
                                    grid-gap: 0.5rem;
                                    padding: 0 0.5rem;
                                }

                                div {
                                    padding: 0.5rem 0;

                                    @media (max-width: 550px) {
                                        font-size: 1.3rem;
                                    }

                                    &.red {
                                        color: var(--color-error);
                                    }
                                }
                            }

                            &:not(.header, .nait-numbers-empty):first-child {
                                border: none;
                            }

                            &.selected {
                                background-color: #e0f0d7;
                                border-bottom: 1.9px solid #379530;
                                border-top: 1px solid #379530;

                                img {
                                    transform: translateY(3px);
                                }
                            }

                            .selected-icon {
                                position: absolute;
                                right: 0.5rem;
                                top: 0;
                            }
                        }

                        .nait-numbers-empty {
                            cursor: default;
                            font-size: var(--font-size--small);
                            padding: 2rem;
                            text-align: center;
                        }
                    }
                }

                .nait-number-list--disabled {
                    opacity: 0.5;
                    pointer-events: none;
                }

                .button-add-new {
                    float: right;
                    margin: 0;
                    width: 200px;

                    @media (max-width: 550px) {
                        width: 100%;
                    }
                }

                .nait-number-add-new {
                    padding: 0 3rem;
                    text-align: left;
                    position: relative;

                    p {
                        color: var(--color-secondary);
                        font-size: var(--font-size--small);
                        line-height: 1rem;
                        margin: 0;
                    }

                    .close {
                        cursor: pointer;
                        position: absolute;
                        right: 0.8rem;
                        top: 0.8rem;

                        &::before {
                            content: '\f057';
                            font-size: 1.8rem;
                        }
                    }

                    .input-container {
                        align-items: center;
                        display: grid;
                        grid-template-columns: 4fr 2fr 1fr;
                        gap: 2rem;
                        justify-content: space-between;

                        @media (max-width: 550px) {
                            gap: 1rem;
                            grid-template-columns: 1fr;
                        }
                    }
                }

                div {
                    @media (max-width: 400px) {
                        :global(input) {
                            font-size: 10px;
                        }
                    }
                }

                .error-message {
                    grid-column: 1/3;
                }
            }

            &.no-nait {
                padding: 1rem 1rem 3rem 1rem;

                span {
                    margin-right: 1rem;
                }

                .error-message {
                    padding: 0.5rem 0;
                    grid-column: 1/3;
                }
            }
            .error-message {
                color: var(--color-error);
            }
        }
    }
</style>
