<script lang="ts" context="module">
    import { AuctionRegisterDocument } from '_config/graphql/tags-generated'
    import { runMutation, runQueryWithPromise } from 'helpers/graphql-query'
    import { AuctionAccountsDocument } from '_config/graphql/tags-generated'
    import { goto } from '$app/navigation'

    export async function getAuctionAccounts(auctionId: number, client: GraphqlClient) {
        const result = await runQueryWithPromise(
            AuctionAccountsDocument,
            {
                auctionId: auctionId,
            } as SomeObject,
            client
        )

        if (result?.error) goto(`/error#gettingAuctionAccount`)

        return result?.data?.auctionAccounts as AuctionAccountsResponse
    }

    export async function auctionRegister(request: AuctionRegisterRequest, client: GraphqlClient) {
        if (!request) return

        const result = await runMutation(AuctionRegisterDocument, request, client)

        if (result?.error) goto(`/error#auctionRegister`)

        return result?.data.auctionRegister
    }
</script>
