<script lang="ts">
    import { displayModal } from '@cucumber/ui/stores/ui.store'
    import { constants } from '_config/constants/auction-format'
    import { auctionLotGallery } from 'stores/auction.store'
    import { AuctionMediaType } from '_config/enums/auction-media-type.enum'
    import VideoPlayer from 'components/video.svelte'
    import { Splide, SplideSlide } from '@splidejs/svelte-splide'
    import '@splidejs/svelte-splide/css'

    import { onMount } from 'svelte'

    let main: Splide
    let thumbs: SplideSlide

    const mainOptions = {
        type: 'slide',
        perPage: 1,
        perMove: 1,
        gap: '1rem',
        pagination: false,
        heightRatio: 0.5,
        width: '100%',
        rewind: false,
    }

    const thumbsOptions = {
        type: 'slide',
        gap: '1rem',
        pagination: false,
        fixedWidth: 110,
        fixedHeight: 70,
        cover: true,
        isNavigation: true,
        updateOnMove: true,
        rewind: false,
    }

    let activeVideoIndex = -1

    let imageUrlList = $auctionLotGallery?.lot.mediaItems
        ?.filter(m => !m.isLogo && (m.mediaType == AuctionMediaType.Image || m.mediaType == AuctionMediaType.Video))
        .map(item => {
            return {
                id: item.id as number,
                url: item.url as string,
                mediaType: item.mediaType as number,
                thumbnailUrl: item.thumbnailUrl as string,
            }
        })

    onMount(() => {
        if (main && thumbs) {
            main.sync(thumbs.splide)
        }

        if (imageUrlList && imageUrlList[0]?.mediaType == AuctionMediaType.Video) {
            activeVideoIndex = 0
        } else activeVideoIndex = -1
    })

    function onSlideMove(event: any) {
        const index = event.detail.index

        if (imageUrlList && imageUrlList[index]?.mediaType == AuctionMediaType.Video) {
            activeVideoIndex = index
        } else activeVideoIndex = -1
    }
</script>

<div class="modal-header">
    <h4 class="text-left">
        <span class="text-orange">{$auctionLotGallery?.auctionFormat === constants.AUCTION_FORMAT.SALEYARD ? 'Pen' : 'Lot'} {$auctionLotGallery?.lot.lotNumber ?? ''}:</span>
        {$auctionLotGallery?.lot.title}
    </h4>
    <a
        href="/"
        on:click|preventDefault={() => {
            $displayModal = null
        }}><i class="fas" /></a
    >
</div>

<div class="modal-body">
    {#if imageUrlList?.length == 1}
        {#if imageUrlList[0].mediaType != AuctionMediaType.Video}
            <img src={imageUrlList[0].url} alt={imageUrlList[0].id.toString()} />
        {:else}
            <VideoPlayer poster={imageUrlList[0].thumbnailUrl} src={imageUrlList[0].url} autoPlay={true} />
        {/if}
    {:else if imageUrlList && imageUrlList?.length > 0}
        <div class="carousel-container">
            <Splide options={mainOptions} bind:this={main} on:moved={onSlideMove}>
                {#each imageUrlList as { id, url, thumbnailUrl, mediaType }, index}
                    <SplideSlide>
                        {#if mediaType != AuctionMediaType.Video}
                            <img src={url} alt={id.toString()} />
                        {:else}
                            <VideoPlayer poster={thumbnailUrl} src={url} autoPlay={index == activeVideoIndex} />
                        {/if}
                    </SplideSlide>
                {/each}
            </Splide>
        </div>
    {/if}
</div>

<div class="modal-footer">
    {#if imageUrlList?.length == 1}
        <!-- <img src={imageUrlList[0].thumbnailUrl} alt={imageUrlList[0].id.toString()} /> -->
    {:else if imageUrlList && imageUrlList?.length > 1}
        <Splide options={thumbsOptions} bind:this={thumbs}>
            {#each imageUrlList as { id, thumbnailUrl }}
                <SplideSlide>
                    <img src={thumbnailUrl} alt={id.toString()} />
                </SplideSlide>
            {/each}
            <div class="splide__arrows" />
        </Splide>
    {/if}
</div>

<style lang="scss">
    .modal-header {
        display: grid;
        grid-template-columns: 1fr max-content;
        align-items: center;

        margin: 0 1rem;

        .text-left {
            text-align: left;

            .text-orange {
                color: var(--color-accent);
            }
        }

        > a {
            i {
                cursor: pointer;
                border: 0;
                color: var(--color-black);
                opacity: 0.2;
                padding: 0;

                &::before {
                    content: '\f00d';
                    font-size: 2.6rem;
                }
            }
        }
    }

    .modal-body {
        min-height: fit-content;

        overflow: hidden;
        margin: 0 1rem;

        .carousel-container {
            :global(div) {
                position: static;
                height: 100%;
            }

            :global(.splide__arrow--next) {
                right: 0;
            }

            :global(.splide__arrow--prev) {
                left: 0;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            aspect-ratio: 2 / 1;
        }
    }

    .modal-footer {
        overflow-x: hidden;
        margin: 2rem;

        :global(.splide__arrows) {
            display: none;
        }
    }
</style>
