<script lang="ts">
    export let poster: string
    export let src: string
    export let autoPlay: boolean = true

    import { onMount } from 'svelte'

    let videoRef: HTMLVideoElement

    $: {
        try {
            if (videoRef) {
                if (autoPlay) {
                    videoRef.play()
                } else {
                    videoRef.pause()
                    videoRef.currentTime = 0
                }
            }
        } catch {}
    }

    onMount(() => {
        try {
            if (videoRef) {
                if (autoPlay) {
                    videoRef.play()
                } else {
                    videoRef.pause()
                    videoRef.currentTime = 0
                }
            }
        } catch {}
    })
</script>

<div>
    <video {poster} {src} controls bind:this={videoRef}>
        <track kind="captions" />
    </video>
</div>

<style>
    div {
        position: relative;
    }

    video {
        width: 100%;
        aspect-ratio: 16/9;
        height: 100%;
    }
</style>
