<script lang="ts">
    export let message: Maybe<string>
    export let selectedInsurance: string
    export let otherInsurance = ''

    import { auctionInsuranceOptions } from '_config/constants/options'
    import Radio from 'components/form/radio.svelte'
</script>

<div class="register-insurance-container">
    <div class="header">Please nominate if you have any insurance requirements</div>

    <div class="message">
        {#if message && message != ''}
            {message}
        {/if}
    </div>

    <div class="options">
        {#each auctionInsuranceOptions as option}
            <Radio id="insurance-radio-{option.value}" name="insurance" value={option.value} label={option.label} bind:selected={selectedInsurance} />
        {/each}
    </div>

    <!-- 
        This has been asked to be hidden. Left in code as discrepency between prod site and uat 
    -->

    <!-- {#if selectedInsurance == 'Other'}
        <div class="other">
            <div class="row-1">Other Provider - Please enter here</div>
            <div class="row-2"><TextBox bind:value={otherInsurance} /></div>
        </div>
    {/if} -->
    {#if selectedInsurance?.length == 0}
        <div class="error-message"><small>Please select your insurance option.</small></div>
    {/if}
</div>

<style lang="scss">
    .register-insurance-container {
        display: grid;
        grid-template-rows: repeat(4, max-content);
        margin-top: 2rem;

        div {
            color: var(--color-secondary);
            font-size: var(--font-size);

            &.header {
                background-color: rgba(60, 76, 84, 0.2);
                font-weight: 700;
                // border-radius: 1rem 1rem 0 0;
                padding: 1rem;
            }

            &:not(.header) {
                background-color: #f5f5f5;
                // padding: 0 1rem;

                &.message {
                    padding: 1rem;
                }
                &.options {
                    padding: 0 1rem;
                    border-top: 1px solid var(--color-grey);
                }

                &.other {
                    display: grid;
                    grid-template-columns: max-content max-content;
                    align-items: center;
                    padding: 1rem;
                    div {
                        padding-right: 1rem;
                    }
                }
            }

            &.error-message {
                padding: 0 1rem;
                color: var(--color-error);
            }
        }
    }

    @media (max-width: 500px) {
        .register-insurance-container {
            div {
                &:not(.header) {
                    &.other {
                        display: grid;
                        grid-template-columns: unset;
                    }
                }
            }
        }
    }
</style>
