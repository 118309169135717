<script lang="ts">
    import { auctionAccount } from '_config/constants/signup'
    import CheckBox from 'components/form/check-box.svelte'
    import TextBox from 'components/form/text-box.svelte'

    export let address1: string
    export let address2: string
    export let city: string
    export let noAddressInformation: boolean = false
    export let post: number | string

    let address1Original: string
    let address2Original: string
    let addressClicked = false
    let cityOriginal: string
    let postOriginal: number | string

    $: {
        if (!noAddressInformation && addressClicked) {
            address1 = address1Original
            address2 = address2Original
            city = cityOriginal
            post = postOriginal
            addressClicked = false
        }

        if (noAddressInformation) {
            address1Original = address1
            address1 = ''
            address2Original = address2
            address2 = ''
            cityOriginal = city
            city = ''
            postOriginal = post
            post = ''
            addressClicked = true
        }
    }

    $: post = post.toString().replace(/[^0-9]/g, '')
</script>

<div class="register-delivery-address-container">
    <div class="header">
        Please Enter your Delivery Address for Transport Arrangements <div class="c" />
    </div>

    <div class="delivery-address">
        <div class="address-item">
            <div class="address-label">Address Line 1</div>
            <div><TextBox required={true} bind:value={address1} disabled={noAddressInformation} showErrorMessage={false} maxlength={auctionAccount.address1} id="address-1" /></div>
        </div>

        <div class="address-item">
            <div class="address-label">City/Town</div>
            <div><TextBox required={true} bind:value={city} disabled={noAddressInformation} showErrorMessage={false} maxlength={auctionAccount.city} id="address-city" /></div>
        </div>

        <div class="address-item">
            <div class="address-label">Address Line 2</div>
            <div>
                <TextBox required={false} bind:value={address2} disabled={noAddressInformation} showErrorMessage={false} maxlength={auctionAccount.address2} id="address-2" />
            </div>
        </div>

        <div class="address-item">
            <div class="address-label">Post Code</div>
            <div>
                <TextBox required={true} bind:value={post} disabled={noAddressInformation} showErrorMessage={false} maxlength={auctionAccount.post} id="address-postcode" />
            </div>
        </div>
    </div>

    <div class="no-code-information">
        <div>I don't know this information. Please contact me after the sale to confirm my details</div>
        <div>
            <CheckBox bind:value={noAddressInformation} id="address-checkbox" />
        </div>
        {#if (address1 == '' || city == '' || post.toString().length < 4) && !noAddressInformation}
            <div class="error-message"><small>Please enter a Delivery Address or select the checkbox above</small></div>
        {/if}
    </div>
</div>

<style lang="scss">
    .register-delivery-address-container {
        display: grid;
        grid-template-rows: repeat(4, max-content);
        margin-top: 2rem;

        div {
            color: var(--color-secondary);
            font-size: var(--font-size);

            &.header {
                background-color: rgba(60, 76, 84, 0.2);
                font-weight: 700;
                padding: 1rem;
            }

            &.delivery-address {
                display: grid;
                grid-template-columns: 60% 40%;
                align-items: center;
                padding: 1rem;
                border-top: 1px solid var(--color-grey);
                width: 100%;

                .address-item {
                    display: grid;
                    grid-template-columns: 30% 70%;
                    align-items: center;
                    padding: 1rem;

                    @media (max-width: 768px) {
                        grid-template-columns: unset;
                    }
                }

                @media (max-width: 768px) {
                    grid-template-columns: unset;
                }

                input:hover {
                    background: #fff !important;
                }

                div {
                    padding-right: 1rem;
                    @media (max-width: 400px) {
                        :global(input) {
                            font-size: 10px;
                        }
                    }
                }

                .error-message {
                    grid-column: 1/3;
                }
            }

            &.participant-code {
                padding-bottom: 20px;
            }

            &.no-code-information {
                display: grid;
                grid-template-columns: 3fr 1fr;
                align-items: center;
                padding: 0 1rem 1rem 1rem;

                div {
                    display: grid;
                    justify-content: start;
                    padding-right: 1rem;
                }
                .error-message {
                    padding: 0.5rem 0;
                    grid-column: 1/3;
                }
            }

            .error-message {
                color: var(--color-error);
            }

            .error-message-hidden {
                height: 34px;
                visibility: hidden;
            }

            &:not(.header) {
                background-color: #f5f5f5;
            }
        }
    }
</style>
