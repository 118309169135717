<script lang="ts">
    import { imagePreviewInfo } from 'stores/auction.store'
    import { onDestroy } from 'svelte'
    import { Splide, SplideSlide, SplideTrack } from '@splidejs/svelte-splide'
    import '@splidejs/svelte-splide/css'

    onDestroy(() => {
        if ($imagePreviewInfo) {
            $imagePreviewInfo = { ...$imagePreviewInfo, imageZoomLevel: 0 }
        }
    })

    let scale: number
    let carousel: Splide

    const carouselOptions = {
        type: 'fade',
        perPage: 1,
        perMove: 1,
        gap: '1rem',
        pagination: false,
        width: '100%',
        height: '100%',
        rewind: false,
        start: $imagePreviewInfo?.activeIndex,
    }

    $: {
        switch ($imagePreviewInfo?.imageZoomLevel) {
            case -2:
                scale = 0.5
                break
            case -1:
                scale = 0.6
                break
            case 0:
                scale = 1
                break
            case 1:
                scale = 1.4
                break
            case 2:
                scale = 1.8
                break
            case 3:
                scale = 2
                break
            default:
                break
        }
    }

    function setActiveIndex() {
        if ($imagePreviewInfo) $imagePreviewInfo.activeIndex = carousel.splide.index
    }
</script>

<div class="carousel-container">
    {#if $imagePreviewInfo?.imagesUrl.length == 1}
        <img style={`transform: scale(${scale}) rotate(0deg);`} src={$imagePreviewInfo?.imagesUrl[0]} alt="" />
    {:else}
        <Splide hasTrack={false} options={carouselOptions} bind:this={carousel} on:moved={setActiveIndex}>
            <SplideTrack>
                {#if $imagePreviewInfo?.imagesUrl}
                    {#each $imagePreviewInfo?.imagesUrl as imagesUrl}
                        <SplideSlide>
                            <img style={`transform: scale(${scale}) rotate(0deg);`} src={imagesUrl} alt="" />
                        </SplideSlide>
                    {/each}
                {/if}
            </SplideTrack>

            <div class="splide__arrows" />
        </Splide>
    {/if}
</div>

<style lang="scss">
    .carousel-container {
        width: 100%;
        height: 90vh;
        overflow: hidden;
        display: grid;

        :global(div) {
            position: static;
        }

        :global(.splide) {
            margin: 0 auto;
            width: 100%;
            height: 100%;

            :global(.splide__track) {
                width: 100%;
                height: 100%;
            }
        }

        :global(button.splide__arrow) {
            width: 5rem;
            height: 5rem;
            z-index: 3;

            @media (max-width: 520px) {
                width: 3rem;
                height: 3rem;
            }
        }

        :global(.splide__arrow--next) {
            right: 0;

            &:active,
            &:focus {
                background: #ccc;
            }
        }

        :global(.splide__arrow--prev) {
            left: 0;

            &:active,
            &:focus {
                background: #ccc;
            }
        }

        img {
            aspect-ratio: 2 / 1;
            object-fit: contain;
            width: 100%;
            height: 100%;
        }

        .splide__slide img {
            width: 100%;
            height: 100%;
            object-fit: contain;

            align-self: center;
            justify-self: center;
        }
    }
</style>
