<script lang="ts">
    import { auctionLotVideoGallery } from 'stores/auction.store'
    import { displayModal } from '@cucumber/ui/stores/ui.store'

    import VideoPlayer from 'components/video.svelte'
</script>

<div class="modal-header">
    <a
        href="/"
        on:click|preventDefault={() => {
            $displayModal = null
        }}><i class="fas" /></a
    >
</div>

<div class="modal-body">
    {#if $auctionLotVideoGallery}
        <VideoPlayer poster={$auctionLotVideoGallery.poster} src={$auctionLotVideoGallery.source} />
    {/if}
</div>

<style lang="scss">
    .modal-header {
        display: grid;
        grid-template-columns: 1fr max-content;
        align-items: center;

        margin: 0 1rem;

        > a {
            text-align: right;

            i {
                cursor: pointer;
                border: 0;
                color: var(--color-black);
                opacity: 0.2;
                padding: 0;

                &::before {
                    content: '\f00d';
                    font-size: 2.6rem;
                }
            }
        }
    }

    .modal-body {
        min-height: fit-content;

        overflow: hidden;
        margin: 0 1rem;
    }
</style>
