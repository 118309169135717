<script lang="ts">
    import { onMount } from 'svelte'
    import { displayModal } from '@cucumber/ui/stores/ui.store'
    import { activeAuction } from 'stores/auction.store'
    import { isAuthenticated } from '_auth/stores/auth.store'

    import RegisterAuctionModal from 'modules/auctions/auction-register.svelte'
    import ConnectAuctionModal from 'modules/auctions/auction-connect.svelte'
    import SignupSuccessModal from 'modules/signup/signup-success-modal.svelte'
    import GalleryCarouselModal from 'modules/auctions/auction-lots/gallery-carousel-modal.svelte'
    import VideoPreviewModal from 'modules/auctions/lot-details/gallery/video-preview-modal.svelte'
    import ImagePreviewModal from 'modules/auctions/lot-details/gallery/image-preview-modal.svelte'

    import Modal from 'components/modal/modal.svelte'
    import ImageModal from 'components/modal/image-modal.svelte'

    let afterLoggedInOpenModal: Maybe<string>
    let afterLoggedInActiveAuctionInfo: Maybe<string>

    onMount(() => {
        afterLoggedInOpenModal = localStorage.getItem('after_action_open_modal')
        afterLoggedInActiveAuctionInfo = localStorage.getItem('after_action_active_auction_info')

        localStorage.removeItem('after_action_open_modal')
        localStorage.removeItem('after_action_active_auction_info')
    })

    $: if ($isAuthenticated) retrieveLocalStorage()

    function retrieveLocalStorage() {
        if (afterLoggedInOpenModal && afterLoggedInActiveAuctionInfo) {
            $activeAuction = JSON.parse(afterLoggedInActiveAuctionInfo)
            $displayModal = afterLoggedInOpenModal
        }
    }

    const components = new Map()

    components.set('register-modal', RegisterAuctionModal)
    components.set('connect-modal', ConnectAuctionModal)
    components.set('signup-success-modal', SignupSuccessModal)
    components.set('gallery-carousel-modal', GalleryCarouselModal)
    components.set('video-preview-modal', VideoPreviewModal)
    components.set('image-preview-modal', ImagePreviewModal)
</script>

{#if $displayModal}
    {#if $displayModal == 'image-preview-modal'}
        <ImageModal>
            <svelte:component this={components.get($displayModal)} />
        </ImageModal>
    {:else}
        <Modal isImageModal={$displayModal == 'gallery-carousel-modal'}>
            <svelte:component this={components.get($displayModal)} />
        </Modal>
    {/if}
{/if}

<style lang="scss">
    :global(h4) {
        padding: 1.5rem 2rem 2rem 5rem;
    }
</style>
